import React, { useContext, useEffect, useRef, useState } from 'react';
import {
  ChakraProvider,
  Box,
  Text,
  Link,
  VStack,
  Code,
  Grid,
  theme,
  Button,
  Stack,
  Spacer,
  SimpleGrid,
  HStack,
  Flex,
  Center,
  Image,
  Wrap,
  WrapItem,
  FormControl,
  Input,
  IconButton,
  FormLabel,
  Checkbox,
} from '@chakra-ui/react';

// import HorizontalScroll from 'react-horizontal-scrolling';

import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route, useNavigate } from 'react-router-dom';
// import your route components too

import { observer } from 'mobx-react-lite';

import MainStore from '../stores/MainStore';

import HowToPlayScreen from './HowToPlayScreen';
import Footer from './Footer';

import Logo from '../assets/logo.png';
import RegisterButtonBG from '../assets/register_button_bg.png';
import DownArrow from '../assets/down_button.png';
import RightArrow from '../assets/right_arrow.png';
import Header from './Header';

import PrizeGems from '../assets/prize_gems.png';
import Tokens from '../assets/token.png';

import { MdAdd } from 'react-icons/md';
import { BiMinus } from 'react-icons/bi';

import Slider from 'react-slick';
// Import css files
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const CartScreen = () => {
  let navigate = useNavigate();
  let store = useContext(MainStore);

  const [screen, setScreen] = useState(0);
  const [viewing, setViewing] = useState(false);
  const [selected, setSelected] = useState('');

  return (
    <Stack>
      <Header />
      <Cart />
      <Footer />
    </Stack>
  );
};

const Cart = ({ product, onClose }) => {
  let navigate = useNavigate();
  let store = useContext(MainStore);

  const [loading, setLoading] = useState(false);
  const [ql, setQL] = useState(false);

  const [cart, setCart] = useState();

  const addressRef = useRef({});

  useEffect(() => {
    getCart();
  }, []);

  const getCart = () => {
    store._getCart().then(res => {
      if (res.data) {
        setCart({ ...res.data });
      }
      setQL(false);
    });
  };

  const validate = () => {
    const address = addressRef.current.validate();

    if (cart.cart_products.length <= 0) {
      store._globalToast({
        title: 'There are no items in your cart!',
        description: `Please add items first`,
        status: 'error',
        isClosable: true,
      });
      return;
    }

    if (!address) {
      return;
    }

    console.log(address);

    setLoading(true);

    store._submitOrder({ cart: cart.cart_products, address }).then(res => {
      console.log(res);

      if (res.code === 'ERR_BAD_REQUEST') {
        store._globalToast({
          title: res.code,
          description: res.response.data.message,
          status: 'error',
          isClosable: true,
        });
      } else {
        store._globalToast({
          title: 'Success!',
          description: `Order submitted`,
          status: 'success',
          isClosable: true,
        });

        //loop through cart in the background and delete everything
        console.log(cart.cart_products);
        store._deleteCart();

        //delete all in cart

        // cart.cart_products.map(c => {
        //   store._deleteItem({ itemID: c.product_id });
        // });

        navigate('/marketplace');
      }

      setLoading(false);
    });

    // store._globalToast({
    //   title: 'Success!',
    //   description: `Purchase complete`,
    //   status: 'success',
    //   isClosable: true,
    // });
  };

  const add = item => {
    //get item quantity + 1
    console.log('add', item.quantity, item.quantity + 1);

    setQL(true);

    store
      ._updateCartItem({ itemID: item.product_id, qty: item.quantity + 1 })
      .then(res => getCart());
  };

  const substract = item => {
    console.log('add', item.quantity, item.quantity - 1);

    setQL(true);

    store
      ._updateCartItem({ itemID: item.product_id, qty: item.quantity - 1 })
      .then(res => getCart());
  };

  const removeItem = item => {
    setQL(true);

    store._deleteItem({ itemID: item.product_id }).then(res => {
      setQL(false);
      store._globalToast({
        title: 'Item removed!',
        description: `${item.product.name} was removed`,
        status: 'error',
        isClosable: true,
      });
      getCart();
    });
  };

  return (
    <Stack>
      <Flex w="100%" pb="80px">
        <VStack w="100%" p={{ base: '10px', md: '30px' }} minH="500px">
          <Box w="100%" color="#fff">
            <Stack>
              <Text
                fontFamily="press_start_2pregular"
                color="#fff"
                fontSize={{ base: '20px', md: '30px' }}
                textShadow="-3px 3px 0px rgba(0, 0, 0, 0.35);"
                textAlign={{ base: 'center', md: 'left' }}
              >
                Checkout
              </Text>
              <Stack>
                {cart && cart.cart_products.length <= 0 && (
                  <Text>You have no items in your cart</Text>
                )}
                {cart &&
                  cart.cart_products.map((item, index) => {
                    return (
                      <Box key={`item${index}`}>
                        <Flex>
                          <Box
                            w="150px"
                            h="125px"
                            background="#9CABC0"
                            borderRadius="15px"
                            backgroundImage={item.product.thumb_url}
                            backgroundPosition={'center'}
                            backgroundRepeat={'no-repeat'}
                            backgroundSize={'contain'}
                          ></Box>
                          <Stack paddingLeft="15px" flex="1">
                            <Text>{item.product.name}</Text>
                            <Flex
                              flexDir={{ base: 'column', md: 'row' }}
                              alignItems={{ base: 'left', md: 'center' }}
                            >
                              <Flex
                                w="250px"
                                h="52px"
                                background="#0090EC"
                                justifyContent="center"
                                alignItems={'center'}
                                borderRadius="50px"
                              >
                                <HStack>
                                  <Image src={Tokens} />
                                  <Text
                                    fontFamily="press_start_2pregular"
                                    fontSize="18px"
                                  >{`${item.price_total} tokens`}</Text>
                                </HStack>
                              </Flex>
                              <HStack m="10px">
                                <IconButton
                                  icon={<MdAdd />}
                                  fontSize="20px"
                                  color="#6D1BE8"
                                  // onClick={add}
                                  onClick={() => add(item)}
                                  loading={ql}
                                ></IconButton>
                                <Center
                                  boxSize="40px"
                                  borderRadius="6px"
                                  border="2px solid white"
                                >
                                  <Text>{item.quantity}</Text>
                                </Center>
                                <IconButton
                                  icon={<BiMinus />}
                                  fontSize="20px"
                                  color="#6D1BE8"
                                  onClick={() => substract(item)}
                                  loading={ql}
                                ></IconButton>
                              </HStack>
                              <Spacer />
                              <Button
                                colorScheme="red"
                                onClick={() => removeItem(item)}
                                loading={ql}
                              >
                                Remove
                              </Button>
                            </Flex>
                          </Stack>
                        </Flex>
                        <hr style={{ marginTop: '30px' }} />
                      </Box>
                    );
                  })}
              </Stack>
              <Text
                fontFamily="press_start_2pregular"
                color="#fff"
                fontSize={{ base: '20px', md: '30px' }}
                textShadow="-3px 3px 0px rgba(0, 0, 0, 0.35);"
                textAlign={{ base: 'center', md: 'left' }}
              >
                General Information
              </Text>
              <Stack spacing="20px">
                <Text>Juan Dela Cruz</Text>
                <Text>juandelacruz@gmail.com</Text>
                <Text>0905738882</Text>
              </Stack>
              <hr style={{ marginTop: '30px' }} />
              <AddressTab myRef={addressRef} />
              <hr style={{ marginTop: '30px' }} />
              <Text
                fontFamily="press_start_2pregular"
                color="#fff"
                fontSize={{ base: '20px', md: '30px' }}
                textShadow="-3px 3px 0px rgba(0, 0, 0, 0.35);"
                textAlign={{ base: 'center', md: 'left' }}
              >
                Payment
              </Text>
              <Stack spacing="20px">
                <HStack>
                  <Text>Subtotal</Text>
                  <Spacer />
                  <Text>{`${cart && cart.price_total}`}</Text>
                </HStack>
                <HStack>
                  <Text>Delivery Fee</Text>
                  <Spacer />
                  <Text>Free</Text>
                </HStack>
              </Stack>
              <Box
                pointerEvents={loading ? 'none' : 'initial'}
                mt="60px !important"
                background="#FF6BE8"
                boxShadow="0px 8px 0px #BB3FA8"
                borderRadius="8px"
                px="40px"
                py="16px"
                cursor="pointer"
                textAlign="center"
                _hover={{
                  transform: 'scale(0.98)',
                }}
                _active={{
                  transform: 'scale(0.95)',
                }}
                onClick={() => validate()}
              >
                <Text fontFamily="press_start_2pregular" color="#fff">
                  {loading ? 'Validating...' : 'Proceed'}
                </Text>
              </Box>
            </Stack>
          </Box>
        </VStack>
        <Center
          m="20px"
          backgroundColor="#FFF4B7"
          minW="160px"
          h="600px"
          display={{ base: 'none', md: 'flex' }}
        >
          <Box>
            <Text textAlign="center">160 x 600</Text>
            <Text textAlign="center">AD PLACEMENT</Text>
          </Box>
        </Center>
      </Flex>
      <VStack my="20px !important">
        <Center
          backgroundColor="#FFF4B7"
          w={{ base: '100%', md: '728px' }}
          h="90px"
        >
          <Text textAlign="center">728 x 90 AD PLACEMENT</Text>
        </Center>
      </VStack>
    </Stack>
  );
};

const AddressTab = ({ myRef }) => {
  const store = useContext(MainStore);

  const [loading, setLoading] = useState(false);

  const [number, setNumber] = useState('');
  const [street, setStreet] = useState('');
  const [subdivision, setSubdivision] = useState('');
  const [building, setBuilding] = useState('');
  const [province, setProvince] = useState('');
  const [city, setCity] = useState('');
  const [barangay, setBarangay] = useState('');
  const [zip, setZip] = useState('');

  const [primary, setPrimary] = useState(false);

  useEffect(() => {
    store._getAddress().then(res => {
      if (res.data.length > 0) {
        const temp = res.data[0];
        //set data
        setNumber(temp.unit);
        setStreet(temp.street);
        setSubdivision(temp.place);
        setBuilding(temp.building);
        setProvince(temp.province);
        setCity(temp.city);
        setBarangay(temp.barangay);
        setPrimary(temp.is_primary);
      }
    });
  }, []);

  const validate = () => {
    let ctr = 0;

    //check for blanks
    if (number === '') ctr++;
    if (street === '') ctr++;
    if (province === '') ctr++;
    if (city === '') ctr++;
    if (barangay === '') ctr++;

    if (ctr > 0) {
      store._globalToast({
        title: 'Incomplete details',
        description: 'Please fill up the form',
        status: 'error',
        isClosable: true,
      });
      return;
    }

    setLoading(true);

    var data = {
      number,
      street,
      subdivision,
      building,
      province,
      city,
      barangay,
    };

    if (primary) {
      store._saveAddress(data).then(res => {
        setLoading(false);

        if (res) {
          const temp = res.data;
          //set data
          setNumber(temp.unit);
          setStreet(temp.street);
          setSubdivision(temp.place);
          setBuilding(temp.building);
          setProvince(temp.province);
          setCity(temp.city);
          setBarangay(temp.barangay);
        }

        // store._globalToast({
        //   title: 'Address updated',
        //   description: '',
        //   status: 'success',
        //   isClosable: true,
        // });
      });
    }

    return data;
  };

  myRef.current.validate = validate;

  return (
    <Stack spacing="20px">
      <Text
        fontFamily="press_start_2pregular"
        color="#fff"
        fontSize={{ base: '20px', md: '30px' }}
        textShadow="-3px 3px 0px rgba(0, 0, 0, 0.35);"
        textAlign={{ base: 'center', md: 'left' }}
      >
        Shipping Address
      </Text>
      <FormControl>
        <FormLabel fontFamily="nunitobold">
          House/Floor/Room # (required)
        </FormLabel>
        <Input
          background="#fff"
          color="#000"
          type="text"
          placeholder="  House/Floor/Room #"
          value={number}
          onChange={e => setNumber(e.target.value)}
        />
      </FormControl>
      <FormControl>
        <FormLabel fontFamily="nunitobold">Street (required)</FormLabel>
        <Input
          background="#fff"
          color="#000"
          type="text"
          placeholder="Street"
          value={street}
          onChange={e => setStreet(e.target.value)}
        />
      </FormControl>
      <FormControl>
        <FormLabel fontFamily="nunitobold">
          Subdivision / Apartment / Village (optional)
        </FormLabel>
        <Input
          background="#fff"
          color="#000"
          type="text"
          placeholder="Subdivision / Apartment / Village"
          value={subdivision}
          onChange={e => setSubdivision(e.target.value)}
        />
      </FormControl>
      <FormControl>
        <FormLabel fontFamily="nunitobold">Building</FormLabel>
        <Input
          background="#fff"
          color="#000"
          type="text"
          placeholder="Building"
          value={building}
          onChange={e => setBuilding(e.target.value)}
        />
      </FormControl>
      <FormControl>
        <FormLabel fontFamily="nunitobold">Province (required)</FormLabel>
        <Input
          background="#fff"
          color="#000"
          type="text"
          placeholder="Province"
          value={province}
          onChange={e => setProvince(e.target.value)}
        />
      </FormControl>
      <FormControl>
        <FormLabel fontFamily="nunitobold">
          City / Municipality (required)
        </FormLabel>
        <Input
          background="#fff"
          color="#000"
          type="text"
          placeholder="City / Municipality"
          value={city}
          onChange={e => setCity(e.target.value)}
        />
      </FormControl>
      <FormControl>
        <FormLabel fontFamily="nunitobold">Barangay (required)</FormLabel>
        <Input
          background="#fff"
          color="#000"
          type="text"
          placeholder="Barangay"
          value={barangay}
          onChange={e => setBarangay(e.target.value)}
        />
      </FormControl>
      <HStack>
        <Checkbox
          isChecked={primary}
          onChange={e => setPrimary(e.target.checked)}
        ></Checkbox>
        <Text>Set as my primary address</Text>
      </HStack>
      {/* <Box
        pointerEvents={loading ? 'none' : 'initial'}
        mt="60px !important"
        background="#FF6BE8"
        boxShadow="0px 8px 0px #BB3FA8"
        borderRadius="8px"
        px="40px"
        py="16px"
        cursor="pointer"
        textAlign="center"
        _hover={{
          transform: 'scale(0.98)',
        }}
        _active={{
          transform: 'scale(0.95)',
        }}
        onClick={() => validate()}
      >
        <Text fontFamily="press_start_2pregular" color="#fff">
          {loading ? 'Validating...' : 'Save'}
        </Text>
      </Box> */}
    </Stack>
  );
};

export default CartScreen;
