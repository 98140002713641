import React, { useContext, useEffect } from 'react';
import {
  ChakraProvider,
  Box,
  Text,
  Link,
  VStack,
  Code,
  Grid,
  theme,
  Button,
  Stack,
  Spacer,
  SimpleGrid,
  HStack,
  Flex,
  Center,
  Circle,
  Icon,
  Image,
} from '@chakra-ui/react';

import {
  FaFacebo,
  FaTwitter,
  FaInstagram,
  FaLinkedinIn,
  FaFacebookF,
  FaEnvelope,
  FaMarker,
  FaPhone,
} from 'react-icons/fa';

import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route, useNavigate } from 'react-router-dom';
// import your route components too

import { observer } from 'mobx-react-lite';

import MainStore from '../stores/MainStore';

import Logo from '../assets/logo.png';

const Footer = () => {
  let navigate = useNavigate();
  return (
    <Box w="100%" p="20px" pt="60px" backgroundColor="#22127B" color="#fff">
      <SimpleGrid columns={{ base: 1, md: 4 }} textAlign="center">
        <Stack>
          <Image src={Logo} h="100px" objectFit="contain" mb="20px" />
        </Stack>
        <Stack spacing="20px">
          <Button color="#fff" variant="link" onClick={() => navigate('/')}>
            Home
          </Button>
          <Button color="#fff" variant="link" onClick={() => navigate('/play')}>
            Play
          </Button>
          <Button color="#fff" variant="link">
            Prizes
          </Button>
          <Button color="#fff" variant="link">
            Marketplace
          </Button>
        </Stack>
        <Stack spacing="20px">
          <Button color="#fff" variant="link">
            Account
          </Button>
          <Button color="#fff" variant="link" onClick={() => navigate('/faqs')}>
            FAQs
          </Button>
          <Button
            color="#fff"
            variant="link"
            onClick={() => navigate('/terms-and-conditions')}
          >{`Terms & Conditions`}</Button>
          <Button
            color="#fff"
            variant="link"
            onClick={() => navigate('/privacy-policy')}
          >
            Privacy Policy
          </Button>
        </Stack>
        <Stack alignItems="right" spacing="20px">
          <SimpleGrid columns={4} my={{ base: '30px', md: '0px' }}>
            <Box m="0 auto" cursor="pointer">
              <Circle size="40px" bg="cyan" color="#22127B">
                <Icon as={FaFacebookF} />
              </Circle>
            </Box>
            <Box m="0 auto" cursor="pointer">
              <Circle size="40px" bg="cyan" color="#22127B">
                <Icon as={FaTwitter} />
              </Circle>
            </Box>
            <Box m="0 auto" cursor="pointer">
              <Circle size="40px" bg="cyan" color="#22127B">
                <Icon as={FaInstagram} />
              </Circle>
            </Box>
            <Box m="0 auto" cursor="pointer">
              <Circle size="40px" bg="cyan" color="#22127B">
                <Icon as={FaLinkedinIn} />
              </Circle>
            </Box>
          </SimpleGrid>
          <Stack alignItems={{ base: 'center', md: 'flex-end' }} spacing="14px">
            <HStack>
              <Icon as={FaEnvelope} />
              <Text>email address goes here</Text>
            </HStack>
            <HStack>
              <Icon as={FaPhone} />
              <Text>mobile number goes here</Text>
            </HStack>
            <HStack>
              <Icon as={FaMarker} />
              <Text>address goes here</Text>
            </HStack>
          </Stack>
        </Stack>
      </SimpleGrid>
      <Text align={{ base: 'center', md: 'right' }} py="40px">
        © Copyright Lucky Pix 2022. All Rights Reserved
      </Text>
    </Box>
  );
};

export default Footer;
