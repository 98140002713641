import React, { useContext, useEffect, useState } from 'react';
import {
  ChakraProvider,
  Box,
  Text,
  Link,
  VStack,
  Code,
  Grid,
  theme,
  Button,
  Stack,
  Spacer,
  SimpleGrid,
  HStack,
  Flex,
  Center,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  FormControl,
  FormLabel,
  FormErrorMessage,
  FormHelperText,
  Input,
  Tag,
  TagLabel,
  TagLeftIcon,
  TagRightIcon,
  TagCloseButton,
  Wrap,
  WrapItem,
  Icon,
  Image,
  InputGroup,
  InputRightElement,
} from '@chakra-ui/react';

import { MdSettings } from 'react-icons/md';
import { FaFacebook, FaTwitter, FaGoogle } from 'react-icons/fa';

// import HorizontalScroll from 'react-horizontal-scrolling';

import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route, useNavigate } from 'react-router-dom';
// import your route components too

import { observer } from 'mobx-react-lite';

import MainStore from '../stores/MainStore';

import Footer from './Footer';
import Header from './Header';

import Logo from '../assets/logo.png';

const LoginScreen = () => {
  let navigate = useNavigate();
  let store = useContext(MainStore);

  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, togglePassword] = useState(false);

  const signIn = () => {
    setLoading(true);
    store
      ._login({ email, password })
      .then(res => {
        setLoading(false);
        navigate('/');
      })
      .catch(err => {
        setLoading(false);
      });
    // navigate('/play')
  };

  return (
    <Stack>
      <Header hideMenu={true} />
      <Flex w="100%" pb="80px">
        <Center
          m="20px"
          backgroundColor="#FFF4B7"
          minW="160px"
          h="600px"
          display={{ base: 'none', md: 'flex' }}
        >
          <Box>
            <Text textAlign="center">160 x 600</Text>
            <Text textAlign="center">AD PLACEMENT</Text>
          </Box>
        </Center>
        <VStack w="100%" minH="500px">
          <Box w="100%" maxW="728px" color="#fff">
            <VStack
              color="#fff"
              pt="40px"
              px="1rem"
              textAlign="left"
              spacing="20px"
            >
              <VStack>
                <Text
                  fontFamily="press_start_2pregular"
                  color="#fff"
                  fontSize="20px"
                  textShadow="-3px 3px 0px rgba(0, 0, 0, 0.35);"
                >
                  Welcome back!
                </Text>
                <HStack>
                  <Text>Don't have an account?</Text>
                  <Button
                    ml="10px !important"
                    variant="link"
                    color="#FFD510"
                    fontFamily="press_start_2pregular"
                    fontSize="10px"
                    onClick={() => navigate('/register')}
                    cursor="pointer"
                    m="4px"
                  >
                    Register
                  </Button>
                </HStack>
                <Flex
                  mt="30px !important"
                  flexDir={{ base: 'column', sm: 'row' }}
                  alignItems={'center'}
                >
                  <Button
                    // disabled={true}
                    w="306px"
                    size="lg"
                    background="#3B5998"
                    borderRadius="20px"
                    _hover={{ opacity: '0.8' }}
                    leftIcon={<FaFacebook />}
                    onClick={() => store._fbLogin()}
                    m="4px"
                  >
                    Sign in with Facebook
                  </Button>
                  <Button
                    // disabled={true}
                    w="306px"
                    size="lg"
                    background="#F34A38"
                    borderRadius="20px"
                    _hover={{ opacity: '0.8' }}
                    leftIcon={<FaGoogle />}
                    onClick={() => store._googleLogin()}
                  >
                    Sign in with Google
                  </Button>
                </Flex>
                <HStack w="100%" textAlign="center" mt="30px !important">
                  <hr style={{ width: '100%', opacity: '0.5' }} />
                  <Text
                    textAlign="center"
                    ml="15px !important"
                    mr="5px !important"
                    whiteSpace="nowrap"
                  >
                    OR SIGN IN WITH YOUR EMAIL
                  </Text>
                  <hr style={{ width: '100%', opacity: '0.5' }} />
                </HStack>
              </VStack>
              <FormControl>
                <FormLabel fontFamily="nunitobold">Email address</FormLabel>
                <Input
                  background="#fff"
                  color="#000"
                  type="email"
                  value={email}
                  onChange={e => setEmail(e.target.value)}
                />
              </FormControl>
              <FormControl>
                <FormLabel fontFamily="nunitobold">Password</FormLabel>
                <InputGroup size="md">
                  <Input
                    background="#fff"
                    color="#000"
                    type={showPassword ? 'text' : 'password'}
                    value={password}
                    onChange={e => setPassword(e.target.value)}
                  />
                  <InputRightElement width="80px">
                    <Button
                      colorScheme="blue"
                      size="sm"
                      onClick={() => togglePassword(!showPassword)}
                    >
                      {showPassword ? 'Hide' : 'Show'}
                    </Button>
                  </InputRightElement>
                </InputGroup>
              </FormControl>

              <HStack>
                <Text>Forgot your password?</Text>
                <Button
                  ml="10px !important"
                  variant="link"
                  color="#FFD510"
                  fontFamily="press_start_2pregular"
                  fontSize="10px"
                  onClick={() => navigate('/forgot-password')}
                  cursor="pointer"
                >
                  Click Here
                </Button>
              </HStack>

              <Box
                pointerEvents={loading ? 'none' : 'initial'}
                mt="60px !important"
                background="#FF6BE8"
                boxShadow="0px 8px 0px #BB3FA8"
                borderRadius="8px"
                px="40px"
                py="16px"
                cursor="pointer"
                _hover={{
                  transform: 'scale(0.98)',
                }}
                _active={{
                  transform: 'scale(0.95)',
                }}
                onClick={() => signIn()}
              >
                <Text fontFamily="press_start_2pregular" color="#fff">
                  {loading ? 'Signing in...' : 'Sign in'}
                </Text>
              </Box>
            </VStack>
          </Box>
        </VStack>
        <Center
          m="20px"
          backgroundColor="#FFF4B7"
          minW="160px"
          h="600px"
          display={{ base: 'none', md: 'flex' }}
        >
          <Box>
            <Text textAlign="center">160 x 600</Text>
            <Text textAlign="center">AD PLACEMENT</Text>
          </Box>
        </Center>
      </Flex>
      <Footer />
    </Stack>
  );
};

export default LoginScreen;
