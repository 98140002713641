import React, { useContext, useEffect, useState } from 'react';
import {
  ChakraProvider,
  Box,
  Text,
  Link,
  VStack,
  Code,
  Grid,
  theme,
  Button,
  Stack,
  Spacer,
  SimpleGrid,
  HStack,
  Flex,
  Center,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  FormControl,
  FormLabel,
  FormErrorMessage,
  FormHelperText,
  Input,
  Tag,
  TagLabel,
  TagLeftIcon,
  TagRightIcon,
  TagCloseButton,
  Wrap,
  WrapItem,
  Icon,
  Image,
  Checkbox,
  CheckboxGroup,
  Circle,
} from '@chakra-ui/react';

import { MdSettings } from 'react-icons/md';
import { FaFacebook, FaTwitter, FaGoogle } from 'react-icons/fa';

// import HorizontalScroll from 'react-horizontal-scrolling';

import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route, useNavigate } from 'react-router-dom';
// import your route components too

import { observer } from 'mobx-react-lite';

import MainStore from '../stores/MainStore';

import Header from './Header';
import Footer from './Footer';

import LogoGcash from '../assets/logo_gcash.png';
import LogoPaymaya from '../assets/logo_paymaya.png';
import LogoCoinsph from '../assets/logo_coinsph.png';
import LogoBpi from '../assets/logo_bpi.png';
import LogoBdo from '../assets/logo_bdo.png';
import LogoMetrobank from '../assets/logo_metrobank.png';

import GreenCheck from '../assets/green_check.png';

// import 'flatpickr/dist/themes/material_green.css';
import 'flatpickr/dist/themes/airbnb.css';
import Flatpickr from 'react-flatpickr';
import moment from 'moment';

const BankScreen = () => {
  let navigate = useNavigate();
  const store = useContext(MainStore);

  const [submitSuccess, setSubmitSuccess] = useState(false);

  useEffect(() => {
    // window.scrollTo(0, 0);
  });

  function submitPayload({ accountName, accountNumber, email }) {
    //validate
    if (accountName === '') {
      store._globalToast({
        title: 'Error',
        description: 'Please enter your account name',
        status: 'error',
        isClosable: true,
      });
      return;
    }

    if (accountNumber === '') {
      store._globalToast({
        title: 'Error',
        description: 'Please enter your account number.',
        status: 'error',
        isClosable: true,
      });
      return;
    }

    //check if blanks
    if (email === '') {
      store._globalToast({
        title: 'Email is required.',
        description: 'Please enter a valid email',
        status: 'error',
        isClosable: true,
      });
      return;
    } else if (!store._validateEmail(email)) {
      store._globalToast({
        title: 'Email is invalid.',
        description: 'Please enter a valid email',
        status: 'error',
        isClosable: true,
      });
      return;
    }

    setSubmitSuccess(true);
  }

  return (
    <Stack>
      <Header hideMenu={true} />
      <Flex w="100%" pb="80px">
        <Center
          m="20px"
          backgroundColor="#FFF4B7"
          minW="160px"
          h="600px"
          display={{ base: 'none', md: 'flex' }}
        >
          <Box>
            <Text textAlign="center">160 x 600</Text>
            <Text textAlign="center">AD PLACEMENT</Text>
          </Box>
        </Center>
        <VStack flex="1" minH="500px">
          <Box w="100%" maxW="728px" color="#fff">
            {!submitSuccess ? (
              <SelectBank submitPayload={submitPayload} />
            ) : (
              <ClaimSubmitted />
            )}
          </Box>
        </VStack>
        <Center
          m="20px"
          backgroundColor="#FFF4B7"
          minW="160px"
          h="600px"
          display={{ base: 'none', md: 'flex' }}
        >
          <Box>
            <Text textAlign="center">160 x 600</Text>
            <Text textAlign="center">AD PLACEMENT</Text>
          </Box>
        </Center>
      </Flex>
      <Footer />
    </Stack>
  );
};

const ClaimSubmitted = () => {
  let navigate = useNavigate();
  const store = useContext(MainStore);

  return (
    <VStack pt="40px" spacing="20px" p={{ base: '10px', md: '0px' }}>
      <Image src={GreenCheck} />
      <Text
        fontFamily="press_start_2pregular"
        color="#fff"
        fontSize="20px"
        // lineHeight="36px"
        textShadow="-3px 3px 0px rgba(0, 0, 0, 0.35);"
      >
        Cash prize will be sent soon
      </Text>
      <Text>{`We’ll send you a message to the email and mobile number you provided when your prize is on the way.`}</Text>
      <Text>{`We appreciate your patience and hope you enjoy the game.`}</Text>
      <br />
      <Box
        mt="60px !important"
        background="#FF6BE8"
        boxShadow="0px 8px 0px #BB3FA8"
        borderRadius="8px"
        px="40px"
        py="16px"
        cursor="pointer"
        _hover={{
          transform: 'scale(0.98)',
        }}
        _active={{
          transform: 'scale(0.95)',
        }}
        onClick={() => {
          navigate('/play');
        }}
      >
        <Text fontFamily="press_start_2pregular" color="#fff">
          Back to Play
        </Text>
      </Box>
    </VStack>
  );
};

const SelectBank = ({ loading, submitPayload }) => {
  let navigate = useNavigate();

  const store = useContext(MainStore);

  const [selected, setSelected] = useState('');
  const [accountName, setAccountName] = useState('');
  const [accountNumber, setAccountNumber] = useState('');
  const [email, setEmail] = useState('');

  const [banks] = useState([
    { name: 'Gcash', label: 'gcash', image: LogoGcash },
    { name: 'PayMaya', label: 'paymaya', image: LogoPaymaya },
    { name: 'Coins.ph', label: 'coinsph', image: LogoCoinsph },
    { name: 'Bank of the Philippine Islands', label: 'bpi', image: LogoBpi },
    { name: 'Banco de Oro', label: 'bdo', image: LogoBdo },
    { name: 'Metrobank', label: 'metrobank', image: LogoMetrobank },
  ]);

  return (
    <VStack pt="40px" spacing="20px" p={{ base: '10px', md: '0px' }}>
      <Text
        fontFamily="press_start_2pregular"
        color="#fff"
        fontSize="20px"
        // lineHeight="36px"
        textShadow="-3px 3px 0px rgba(0, 0, 0, 0.35);"
      >
        Claim your major prize
      </Text>
      <Text>{`Select your preferred E-wallet or bank where we will send your cash prize`}</Text>
      <br />
      <SimpleGrid columns={{ base: 2, md: 3 }} spacing={'20px'}>
        {banks.map((bank, index) => {
          return (
            <Center
              key={`bank${index}`}
              border={
                selected.label === bank.label ? '3px solid #FF6BE8' : 'none'
              }
              boxSize="120px"
              background="white"
              borderRadius="24"
              cursor="pointer"
              onClick={() => {
                setSelected(bank);
              }}
            >
              <Image src={bank.image} />
            </Center>
          );
        })}
      </SimpleGrid>
      {selected && (
        <>
          <Box>
            <HStack>
              <Circle size="40px" background="white" padding="4px">
                <Image src={selected.image} />
              </Circle>
              <Text>{selected.name}</Text>
            </HStack>
          </Box>
          <Stack w="100%">
            <FormControl>
              <FormLabel fontFamily="nunitobold">Account Name</FormLabel>
              <Input
                background="#fff"
                color="#000"
                type="text"
                placeholder="Max of 30 characters"
                value={accountName}
                onChange={e => setAccountName(e.target.value)}
              />
            </FormControl>
            <FormControl>
              <FormLabel fontFamily="nunitobold">Account Number</FormLabel>
              <Input
                background="#fff"
                color="#000"
                type="text"
                placeholder="12, 19 Digits"
                value={accountNumber}
                onChange={e => setAccountNumber(e.target.value)}
              />
            </FormControl>
            <FormControl>
              <FormLabel fontFamily="nunitobold">Send receipt to</FormLabel>
              <Input
                background="#fff"
                color="#000"
                type="text"
                placeholder="name@email.com"
                value={email}
                onChange={e => setEmail(e.target.value)}
              />
            </FormControl>
          </Stack>
          <Box
            pointerEvents={loading ? 'none' : 'initial'}
            mt="60px !important"
            background="#FF6BE8"
            boxShadow="0px 8px 0px #BB3FA8"
            borderRadius="8px"
            px="40px"
            py="16px"
            cursor="pointer"
            _hover={{
              transform: 'scale(0.98)',
            }}
            _active={{
              transform: 'scale(0.95)',
            }}
            onClick={() => {
              submitPayload({ accountName, accountNumber, email });
            }}
          >
            <Text fontFamily="press_start_2pregular" color="#fff">
              {loading ? 'Submitting...' : 'Claim prize'}
            </Text>
          </Box>
        </>
      )}
    </VStack>
  );
};

export default BankScreen;
