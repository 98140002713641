import React, { useContext, useEffect, useState } from 'react';
import {
  ChakraProvider,
  Box,
  Text,
  Link,
  VStack,
  Code,
  Grid,
  theme,
  Button,
  Stack,
  Spacer,
  SimpleGrid,
  HStack,
  Flex,
  Center,
  Image,
  Wrap,
  WrapItem,
  FormControl,
  Input,
  IconButton,
} from '@chakra-ui/react';

// import HorizontalScroll from 'react-horizontal-scrolling';

import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route, useNavigate } from 'react-router-dom';
// import your route components too

import { observer } from 'mobx-react-lite';

import MainStore from '../stores/MainStore';

import HowToPlayScreen from './HowToPlayScreen';
import Footer from './Footer';

import Logo from '../assets/logo.png';
import RegisterButtonBG from '../assets/register_button_bg.png';
import DownArrow from '../assets/down_button.png';
import RightArrow from '../assets/right_arrow.png';
import Header from './Header';

import PrizeGems from '../assets/prize_gems.png';
import Tokens from '../assets/token.png';

import { MdAdd } from 'react-icons/md';
import { BiMinus } from 'react-icons/bi';

import Slider from 'react-slick';
// Import css files
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const MarketplaceScreen = () => {
  let navigate = useNavigate();
  let store = useContext(MainStore);

  const [screen, setScreen] = useState(0);
  const [viewing, setViewing] = useState(false);
  const [selected, setSelected] = useState('');

  useEffect(() => {});

  return (
    <Stack>
      <Header />
      {!viewing ? (
        <MarketPlaceList
          onSelect={e => {
            setSelected(e);
            setViewing(true);
          }}
        />
      ) : (
        <ViewProduct product={selected} onClose={() => setViewing(false)} />
      )}
      <Footer />
    </Stack>
  );
};

const ViewProduct = ({ product, onClose }) => {
  let navigate = useNavigate();
  let store = useContext(MainStore);

  const [qty, setQty] = useState(1);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const add = () => {
    setQty(qty + 1);
  };

  const substract = () => {
    if (qty - 1 > 0) {
      setQty(qty - 1);
    }
  };

  const addToCart = () => {
    setLoading(true);
    store._addCartItem({ itemID: product.id, qty }).then(res => {
      setLoading(false);
      onClose();

      store._globalToast({
        title: 'Added!',
        description: `Product ${product.name} x${qty} has been added to your cart`,
        status: 'success',
        isClosable: true,
      });
    });
  };

  return (
    <Stack>
      <Flex w="100%" pb="80px">
        <VStack flex="1" minH="500px">
          <Box p="12px" w="100%" color="#fff">
            <Stack>
              <HStack>
                <Text>Marketplace</Text>
                <Text color="#FFD510">{`>`}</Text>
                <Text>{product.name}</Text>
              </HStack>
              <Flex flexDirection={{ base: 'column', md: 'row' }}>
                <Flex>
                  <Box
                    w="398px"
                    h="332px"
                    background="#9CABC0"
                    boxshadow="-5px 5px 0px rgba(0, 0, 0, 0.25"
                    borderRadius="15px"
                    backgroundImage={product.thumb_url}
                    backgroundPosition={'center'}
                    backgroundRepeat={'no-repeat'}
                    backgroundSize={'contain'}
                    mb="20px"
                  ></Box>
                </Flex>
                <Stack padding={{ base: '2px', md: '20px' }}>
                  <Text
                    fontFamily="press_start_2pregular"
                    color="#fff"
                    fontSize="30px"
                  >
                    {product.name}
                  </Text>
                  <Text>{product.description}.</Text>
                  <Flex
                    w="250px"
                    h="52px"
                    background="#0090EC"
                    justifyContent="center"
                    alignItems="center"
                    borderRadius="50px"
                  >
                    <HStack>
                      <Image src={Tokens} />
                      <Text
                        fontFamily="press_start_2pregular"
                        fontSize="18px"
                      >{`50 tokens`}</Text>
                    </HStack>
                  </Flex>
                  <br />
                  <HStack>
                    <IconButton
                      icon={<MdAdd />}
                      fontSize="20px"
                      color="#6D1BE8"
                      onClick={add}
                      loading={loading}
                    ></IconButton>
                    <Center
                      boxSize="40px"
                      borderRadius="6px"
                      border="2px solid white"
                    >
                      <Text>{qty}</Text>
                    </Center>
                    <IconButton
                      icon={<BiMinus />}
                      fontSize="20px"
                      color="#6D1BE8"
                      onClick={substract}
                      loading={loading}
                    ></IconButton>
                  </HStack>
                  <HStack>
                    <Box
                      mt="60px !important"
                      background="#FF6BE8"
                      boxShadow="0px 8px 0px #BB3FA8"
                      borderRadius="8px"
                      px="30px"
                      py="10px"
                      cursor="pointer"
                      _hover={{
                        transform: 'scale(0.98)',
                      }}
                      _active={{
                        transform: 'scale(0.95)',
                      }}
                      onClick={onClose}
                    >
                      <Text fontFamily="press_start_2pregular" color="#fff">
                        Cancel
                      </Text>
                    </Box>
                    <Box
                      mt="60px !important"
                      background="#FF6BE8"
                      boxShadow="0px 8px 0px #BB3FA8"
                      borderRadius="8px"
                      px="30px"
                      py="10px"
                      cursor="pointer"
                      _hover={{
                        transform: 'scale(0.98)',
                      }}
                      _active={{
                        transform: 'scale(0.95)',
                      }}
                      onClick={addToCart}
                      loading={loading}
                      pointerEvents={loading ? 'none' : 'initial'}
                    >
                      <Text
                        fontFamily="press_start_2pregular"
                        color="#fff"
                        whiteSpace={'nowrap'}
                      >
                        Add to Cart
                      </Text>
                    </Box>
                  </HStack>
                  <HStack>
                    <Box
                      mt="10px !important"
                      background="#FF6BE8"
                      boxShadow="0px 8px 0px #BB3FA8"
                      borderRadius="8px"
                      px="30px"
                      py="10px"
                      cursor="pointer"
                      _hover={{
                        transform: 'scale(0.98)',
                      }}
                      _active={{
                        transform: 'scale(0.95)',
                      }}
                      onClick={() => {
                        navigate('/cart');
                      }}
                    >
                      <Text fontFamily="press_start_2pregular" color="#fff">
                        Go to Cart
                      </Text>
                    </Box>
                  </HStack>
                </Stack>
              </Flex>
            </Stack>
          </Box>
        </VStack>
        <Center
          m="20px"
          backgroundColor="#FFF4B7"
          minW="160px"
          h="600px"
          display={{ base: 'none', md: 'flex' }}
        >
          <Box>
            <Text textAlign="center">160 x 600</Text>
            <Text textAlign="center">AD PLACEMENT</Text>
          </Box>
        </Center>
      </Flex>
    </Stack>
  );
};

const MarketPlaceList = observer(({ onSelect }) => {
  let navigate = useNavigate();
  let store = useContext(MainStore);

  const [products, setProducts] = useState([]);
  const [cart, setCart] = useState(0);

  useEffect(() => {
    window.scrollTo(0, 0);
  });

  useEffect(() => {
    store._getProducts().then(res => {
      if (res.data) {
        setProducts(res.data);
      }
    });
    store._getCart().then(res => {
      setCart(res.data.cart_products.length);
    });
  }, []);

  return (
    <Stack>
      <Stack px="8px" pb="80px !important">
        <HStack p="20px">
          <Box flex="1">
            <Flex alignItems={'center'} flexDir={{ base: 'column', md: 'row' }}>
              <Text
                fontFamily="press_start_2pregular"
                color="#fff"
                fontSize="30px"
                m="10px"
              >
                Marketplace
              </Text>
              <Box
                m="10px"
                background="#FF6BE8"
                boxShadow="0px 8px 0px #BB3FA8"
                borderRadius="8px"
                px="30px"
                py="10px"
                cursor="pointer"
                _hover={{
                  transform: 'scale(0.98)',
                }}
                _active={{
                  transform: 'scale(0.95)',
                }}
                onClick={() => {
                  navigate('/cart');
                }}
              >
                <Text fontFamily="press_start_2pregular" color="#fff">
                  {`Cart (${cart})`}
                </Text>
              </Box>
              <Spacer />
              <FormControl w={{ base: '100%', md: '320px' }}>
                {/* <FormLabel fontFamily="nunitobold">Account Name</FormLabel> */}
                <Input
                  m="10px"
                  background="#fff"
                  color="#000"
                  type="text"
                  placeholder="Search for products"
                  // value={accountName}
                  // onChange={e => setAccountName(e.target.value)}
                />
              </FormControl>
            </Flex>
            <Box
              mt="20px !important"
              w={{
                base: 'calc(100vw - 80px)',
                md: 'calc(100vw - 80px)',
              }}
            >
              <HeroCarousel>
                {store.homeData &&
                  store.homeData.newsletters.map((n, index) => {
                    return (
                      <Box
                        key={`newsletter_${index}`}
                        backgroundColor="#707070"
                        color="white"
                        h="600px"
                        backgroundImage={`url('${n.image_url}')`}
                        backgroundSize="cover"
                        backgroundPosition="center"
                      >
                        <Center h="100%">
                          <Text>{n.title}</Text>
                        </Center>
                      </Box>
                    );
                  })}
                <Box backgroundColor="#0034BB" color="white" h="600px">
                  <VStack h="100%" justifyContent="center">
                    <Text
                      mt="60px !important"
                      fontFamily="press_start_2pregular"
                      color="#fff"
                      fontSize="52px"
                      textAlign="center"
                    >
                      FEELING LUCKY?
                    </Text>
                    <Box
                      mt="60px !important"
                      background="#FF6BE8"
                      boxShadow="0px 8px 0px #BB3FA8"
                      borderRadius="8px"
                      px="30px"
                      py="10px"
                      cursor="pointer"
                      _hover={{
                        transform: 'scale(0.98)',
                      }}
                      _active={{
                        transform: 'scale(0.95)',
                      }}
                      onClick={() => {
                        navigate('/play');
                        // regOrPlay();
                      }}
                    >
                      <Text fontFamily="press_start_2pregular" color="#fff">
                        Play Now
                      </Text>
                    </Box>
                  </VStack>
                </Box>
              </HeroCarousel>
            </Box>
          </Box>
        </HStack>
        <Wrap justify={'center'}>
          {products.map((product, index) => {
            return (
              <WrapItem key={`item${index}`}>
                <Box
                  key={`market${index}`}
                  display="inline-block"
                  w="270px"
                  h="315px"
                  border="3px solid #000000"
                  borderRadius="10px"
                  background="#2128AB"
                  boxShadow="-4px 4px 0px rgba(0, 0, 0, 0.25)"
                  mr="20px"
                  mb="20px"
                  cursor="pointer"
                  onClick={() => {
                    onSelect(product);
                  }}
                >
                  <VStack pt="20px">
                    <Box
                      pos="relative"
                      w="240px"
                      h="200px"
                      background="#9CABC0"
                      borderRadius="15px"
                      backgroundImage={product.thumb_url}
                      backgroundPosition={'center'}
                      backgroundRepeat={'no-repeat'}
                      backgroundSize={'contain'}
                      // m="0 auto"
                    >
                      <Flex
                        pos="absolute"
                        right="0px"
                        w="106px"
                        h="35px"
                        background="#0090EC"
                        borderRadius={'0px 15px'}
                        justifyContent="center"
                        alignItems="center"
                      >
                        <HStack>
                          <Image src={Tokens} />
                          <Text
                            fontSize="14px"
                            color="white"
                          >{`${product.price} tokens`}</Text>
                        </HStack>
                      </Flex>
                    </Box>
                    <Box w="240px">
                      <Text color="#fff">{product.name}</Text>
                      <Text color="#fff">{product.description}</Text>
                    </Box>
                    <Box w="240px" m="0 auto"></Box>
                  </VStack>
                </Box>
              </WrapItem>
            );
          })}
        </Wrap>
      </Stack>
      <VStack my="20px !important">
        <Center
          backgroundColor="#FFF4B7"
          w={{ base: '100%', md: '728px' }}
          h="90px"
        >
          <Text textAlign="center">728 x 90 AD PLACEMENT</Text>
        </Center>
      </VStack>
    </Stack>
  );
});

const HeroCarousel = ({ children }) => {
  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    speed: 1000,
    autoplaySpeed: 3000,
    cssEase: 'linear',
    arrows: false,
  };

  return (
    <Box
      borderRadius="30px"
      overflow="hidden"
      boxShadow="-8px 8px 0px rgba(0, 0, 0, 0.25);"
    >
      <Slider {...settings}>{children}</Slider>
    </Box>
  );
};

export default MarketplaceScreen;
