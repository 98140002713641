import React, { useContext, useEffect } from 'react';
import {
  ChakraProvider,
  Box,
  Text,
  Link,
  VStack,
  Code,
  Grid,
  theme,
  Button,
  Stack,
  Spacer,
  SimpleGrid,
  HStack,
  Flex,
  Center,
  Image,
  useBreakpointValue,
} from '@chakra-ui/react';

// import HorizontalScroll from 'react-horizontal-scrolling';

import ReactDOM from 'react-dom/client';
import {
  BrowserRouter,
  Routes,
  Route,
  useNavigate,
  useLocation,
} from 'react-router-dom';
// import your route components too

import { observer } from 'mobx-react-lite';

import MainStore from '../stores/MainStore';

import HowToPlayScreen from './HowToPlayScreen';
import Footer from './Footer';

import Logo from '../assets/logo.png';
import RegisterButtonBG from '../assets/register_button_bg.png';
import DownArrow from '../assets/down_button.png';
import RightArrow from '../assets/right_arrow.png';
import Header from './Header';

import PrizeGems from '../assets/prize_gems.png';

import Slider from 'react-slick';
// Import css files
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const HomeScreen = observer(() => {
  const navigate = useNavigate();
  const location = useLocation();
  const store = useContext(MainStore);

  useEffect(() => {
    store._getHome();
  }, [location]);

  return (
    <Stack>
      <Header />
      <Stack px="8px" pb="80px !important">
        <HStack p="20px">
          <Box flex="1">
            <Box
              w={{
                base: 'calc(100vw - 80px)',
                md: 'calc(100vw - 180px - 80px)',
              }}
            >
              <HeroCarousel>
                {store.homeData &&
                  store.homeData.newsletters.map((n, index) => {
                    return (
                      <Box
                        key={`newsletter_${index}`}
                        backgroundColor="#707070"
                        color="white"
                        h="600px"
                        backgroundImage={`url('${n.image_url}')`}
                        backgroundSize="cover"
                        backgroundPosition="center"
                      >
                        <Center h="100%">
                          <Text>{n.title}</Text>
                        </Center>
                      </Box>
                    );
                  })}
                {/* <Box backgroundColor="#707070" color="white" h="600px">
                  <Center h="100%">
                    <Text>BANNER AREA FOR THE HERO CAROUSEL 1</Text>
                  </Center>
                </Box>
                <Box backgroundColor="#707070" color="white" h="600px">
                  <Center h="100%">
                    <Text>BANNER AREA FOR THE HERO CAROUSEL 2</Text>
                  </Center>
                </Box> */}
                <Box backgroundColor="#0034BB" color="white" h="600px">
                  <VStack h="100%" justifyContent="center">
                    <Text
                      mt="60px !important"
                      fontFamily="press_start_2pregular"
                      color="#fff"
                      fontSize="52px"
                      textAlign="center"
                    >
                      FEELING LUCKY?
                    </Text>
                    <Box
                      mt="60px !important"
                      background="#FF6BE8"
                      boxShadow="0px 8px 0px #BB3FA8"
                      borderRadius="8px"
                      px="30px"
                      py="10px"
                      cursor="pointer"
                      _hover={{
                        transform: 'scale(0.98)',
                      }}
                      _active={{
                        transform: 'scale(0.95)',
                      }}
                      onClick={() => {
                        navigate('/play');
                        // regOrPlay();
                      }}
                    >
                      <Text fontFamily="press_start_2pregular" color="#fff">
                        Play Now
                      </Text>
                    </Box>
                  </VStack>
                </Box>
              </HeroCarousel>
            </Box>
          </Box>
          <Center
            mx="20px"
            backgroundColor="#FFF4B7"
            minW="160px"
            h="600px"
            display={{ base: 'none', md: 'flex' }}
          >
            <Box>
              <Text textAlign="center">160 x 600</Text>
              <Text textAlign="center">AD PLACEMENT</Text>
            </Box>
          </Center>
        </HStack>
        <HStack>
          <Text fontFamily="press_start_2pregular" color="#fff" fontSize="20px">
            Today's Prizes
          </Text>
          <Text color="#fff">RESETS IN 00:00:00</Text>
        </HStack>
        <Box p="40px !important">
          <PrizesCarousel>
            {store.homeData &&
              store.homeData.todays_prizes.map((p, index) => {
                return (
                  <PrizeBox
                    key={`prize_${index}`}
                    type={p.type}
                    amount={`PHP${p.prize}`}
                    won={p.user}
                    image_url={p.image_url}
                  />
                );
              })}
            {/* <PrizeBox major amount="PHP10,000" />
            <PrizeBox amount="PHP2,000" />
            <PrizeBox major amount="PHP10,000" won />
            <PrizeBox major amount="PHP10,000" />
            <PrizeBox amount="PHP2,000" />
            <PrizeBox major amount="PHP10,000" won /> */}
          </PrizesCarousel>
        </Box>
        <VStack my="40px !important">
          <Center
            backgroundColor="#FFF4B7"
            w={{ base: '100%', md: '728px' }}
            h="90px"
          >
            <Text textAlign="center">728 x 90 AD PLACEMENT</Text>
          </Center>
        </VStack>
        <Text fontFamily="press_start_2pregular" color="#fff" fontSize="20px">
          Winners
        </Text>
        <Flex
          w="100%"
          px="8px"
          minHeight={'260px'}
          flexDir={{ base: 'column', md: 'row' }}
        >
          <Stack flex="1">
            <>
              {store.homeData &&
                [...store.homeData.winners]
                  .filter(w => w.type !== 'token')
                  .map((w, index) => {
                    return <WinnerBox key={`w${index}`} data={w} />;
                  })}
            </>
            <>
              {store.homeData &&
                [...store.homeData.winners].filter(w => w.type !== 'token')
                  .length <= 0 && (
                  <Text color="#fff">No Winners as of Today</Text>
                )}
            </>
          </Stack>
          <VStack
            ml={{ base: '0px', md: '20px' }}
            flex="1"
            backgroundColor="#707070"
            boxShadow="-8px 8px 0px rgba(0, 0, 0, 0.25)"
            borderRadius="30px"
            justifyContent="center"
          >
            <Box p="12px">
              <Text color="#fff">BANNER AREA FOR THE MARKETPLACE</Text>
            </Box>

            <Box
              w="90%"
              mt="60px !important"
              background="#FF6BE8"
              boxShadow="0px 8px 0px #BB3FA8"
              borderRadius="8px"
              px="30px"
              py="10px"
              cursor="pointer"
              _hover={{
                transform: 'scale(0.98)',
              }}
              _active={{
                transform: 'scale(0.95)',
              }}
              onClick={() => {
                // navigate('/play');
                // regOrPlay();
              }}
            >
              <Text
                textAlign={'center'}
                fontFamily="press_start_2pregular"
                color="#fff"
                // whiteSpace={'nowrap'}
              >
                Explore the Marketplace
              </Text>
            </Box>
          </VStack>
        </Flex>
        <Center
          mt="60px !important"
          backgroundColor="#0090EC"
          border="5px solid #000000"
          boxShadow="-8px 8px 0px #000000"
          w="80%"
          h="175px"
          m="80px auto !important"
        >
          <HStack spacing="20px" p="14px">
            <Text
              fontFamily="press_start_2pregular"
              color="#fff"
              textShadow="-3px 3px 0px rgba(0, 0, 0, 0.35)"
            >
              Questions? We've got you!
            </Text>
            <Box
              background="#FF6BE8"
              boxShadow="0px 8px 0px #BB3FA8"
              borderRadius="8px"
              px="30px"
              py="10px"
              cursor="pointer"
              _hover={{
                transform: 'scale(0.98)',
              }}
              _active={{
                transform: 'scale(0.95)',
              }}
              onClick={() => {
                navigate('/faqs');
              }}
            >
              <Text fontFamily="press_start_2pregular" color="#fff">
                View FAQs
              </Text>
            </Box>
          </HStack>
        </Center>
        <VStack my="20px !important">
          <Center
            backgroundColor="#FFF4B7"
            w={{ base: '100%', md: '728px' }}
            h="90px"
          >
            <Text textAlign="center">728 x 90 AD PLACEMENT</Text>
          </Center>
        </VStack>
      </Stack>
      <Footer />
    </Stack>
  );
});

const WinnerBox = props => {
  return (
    <Box
      color="white"
      backgroundColor="#2128AB"
      border="3px solid #000"
      borderRadius="10px"
      boxShadow="-4px 4px 0px rgba(0, 0, 0, 0.25);"
      p="20px"
    >
      <HStack>
        <Box backgroundColor="grey" w="80px" h="80px" borderRadius="20px"></Box>
        <Stack>
          {props.data ? (
            <>
              <Text fontWeight="bold">
                {`${props.data.user.name} just won ${props.data.prize} ${
                  props.data.type === 'token' ? 'Tokens' : 'Pesos'
                }!`}
              </Text>
              <Text>{props.data.opened_at_for_humans}</Text>
            </>
          ) : (
            <Text>No Winner</Text>
          )}
        </Stack>
      </HStack>
    </Box>
  );
};

const HeroCarousel = ({ children }) => {
  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    speed: 1000,
    autoplaySpeed: 3000,
    cssEase: 'linear',
    arrows: false,
  };

  return (
    <Box
      borderRadius="30px"
      overflow="hidden"
      boxShadow="-8px 8px 0px rgba(0, 0, 0, 0.25);"
    >
      <Slider {...settings}>{children}</Slider>
    </Box>
  );
};

const PrizesCarousel = ({ children }) => {
  const slideCount = useBreakpointValue({
    base: 1,
    md: 2,
    lg: 3,
  });

  return (
    <Box>
      <Slider
        dots={true}
        infinite={true}
        speed={500}
        slidesToShow={slideCount}
        slidesToScroll={slideCount}
      >
        {children}
      </Slider>
    </Box>
  );
};

const PrizeBox = props => {
  return (
    <Box pos="relative" m="0 auto" maxW="350px" h="480px">
      {/* <Image src={PrizeGems} pos="fixed" top="0px" right="0px" zIndex={100} /> */}

      {props.won && (
        <Box
          pos="absolute"
          zIndex={101}
          backgroundColor="#FFD510"
          border="4px solid #000"
          borderRadius="13px"
          p="7px 13px"
          left="50%"
          transform="translateX(-50%)"
        >
          <Text
            fontFamily="press_start_2pregular"
            color="#000"
            fontSize="18px"
            whiteSpace="nowrap"
          >
            PRIZE WON
          </Text>
        </Box>
      )}
      <Box
        backgroundColor="#2128AB"
        h="414px"
        border="3px solid #000"
        borderRadius="12px"
        boxShadow=" -5.18399px 5.18399px 0px rgba(0, 0, 0, 0.25)"
        style={props.won ? { filter: 'grayscale(100%)' } : {}}
      >
        <VStack>
          <Box
            m="20px !important"
            mb="0px"
            w="auto"
            h="260px"
            borderRadius="20px"
            overflow="hidden"
          >
            <Image
              // src={'https://picsum.photos/500'}
              src={props.image_url}
              objectPosition="center"
              objectFit="cover"
            />
          </Box>
          <Text
            fontFamily="press_start_2pregular"
            color={props.type === 'major' ? '#FFD510' : '#69E0D5'}
          >
            {`${props.type.major ? 'MAJOR' : 'MINOR'} PRIZE`}
          </Text>
          <Text fontFamily="press_start_2pregular" color="#fff" fontSize="28px">
            {props.amount}
          </Text>
        </VStack>
      </Box>
      {props.won && (
        <Box color="#fff" mt="12px !important">
          <HStack justifyContent="center">
            <Text>Congratulations, </Text>
            <Text fontWeight="bold">@player!</Text>
          </HStack>
        </Box>
      )}
    </Box>
  );
};

export default HomeScreen;
