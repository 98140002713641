import React, { useContext, useEffect } from 'react';
import {
  ChakraProvider,
  Box,
  Text,
  Link,
  Code,
  Grid,
  theme,
  Button,
  Stack,
  Spacer,
  SimpleGrid,
  HStack,
  VStack,
  Flex,
  Center,
  FormControl,
  FormLabel,
  Input,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Image,
  Container,
} from '@chakra-ui/react';

import { BsQuestionSquareFill } from 'react-icons/bs';

import ReactDOM from 'react-dom/client';
import {
  BrowserRouter,
  Routes,
  Route,
  useNavigate,
  useParams,
  Navigate,
  // Outlet,
} from 'react-router-dom';
// import your route components too

import { observer } from 'mobx-react-lite';

import MainStore from './stores/MainStore';

//import screens
import LandingScreen from './screens/LandingScreen';
import RegisterScreen from './screens/RegisterScreen';
import LoginScreen from './screens/Login';
import PlayScreen from './screens/PlayScreen';
import FAQScreen from './screens/FAQScreen';
import PrivacyScreen from './screens/PrivacyScreen';
import TermsScreen from './screens/TermsScreen';
import HomeScreen from './screens/HomeScreen';
import HowToPlayScreen from './screens/HowToPlayScreen';
import Header from './screens/Header';
import Footer from './screens/Footer';
import TopicsScreen from './screens/TopicsScreen';
import AccountScreen from './screens/AccountScreen';
import MarketplaceScreen from './screens/MarketplaceScreen';
import BankScreen from './screens/BankScreen';
import CartScreen from './screens/CartScreen';
import ForgotPasswordScreen from './screens/ForgotPasswordScreen';

import CloseModal from './assets/close_button.png';

const App = observer(() => {
  const store = useContext(MainStore);

  return (
    <ChakraProvider theme={theme}>
      {store.baseURL ===
        'https://backend-staging-4l9zhskmdajbr6fz.playluckypix.com' && (
        <Box position="absolute" pointerEvents={'none'}>
          <div style={{ color: '#fff' }}>{`${store.baseURL}`}</div>
          <div style={{ color: '#fff' }}>{`Dev Build: ${store.buildDate}`}</div>
        </Box>
      )}
      <BrowserRouter>
        <Routes>
          <Route path="*" element={<NotFound />} />
          <Route path="/" element={<LandingScreen />} />
          <Route
            path="claim"
            element={
              <PrivateRoute>
                <BankScreen />
              </PrivateRoute>
            }
          />
          <Route
            path="topics"
            element={
              <PrivateRoute>
                <TopicsScreen />
              </PrivateRoute>
            }
          />
          <Route
            path="home"
            element={
              <PrivateRoute>
                <HomeScreen />
              </PrivateRoute>
            }
          />
          <Route
            path="marketplace"
            element={
              <PrivateRoute>
                <MarketplaceScreen />
              </PrivateRoute>
            }
          />
          <Route
            path="cart"
            element={
              <PrivateRoute>
                <CartScreen />
              </PrivateRoute>
            }
          />
          <Route
            path="account"
            element={
              <PrivateRoute>
                <AccountScreen />
              </PrivateRoute>
            }
          />
          <Route path="how-to-play" element={<HowToPlayScreenMain />} />
          <Route path="login" element={<LoginScreen />} />
          <Route path="forgot-password" element={<ForgotPasswordScreen />} />
          <Route path="register" element={<RegisterScreen />} />
          <Route
            path="play"
            element={
              <PrivateRoute>
                <PlayScreen />
              </PrivateRoute>
            }
          />
          <Route path="faqs" element={<FAQScreen />} />
          <Route path="privacy-policy" element={<PrivacyScreen />} />
          <Route path="terms-and-conditions" element={<TermsScreen />} />
          <Route path="/auth/:type/callback" element={<CallBackScreen />} />
        </Routes>
      </BrowserRouter>
      <GlobalModal />
    </ChakraProvider>
  );
});

const PrivateRoute = observer(({ children }) => {
  const store = useContext(MainStore);
  return store.userData ? <>{children}</> : <Navigate to="/" />;
});

const NotFound = props => {
  const navigate = useNavigate();

  return (
    <VStack h="100vh" justifyContent={'center'} alignItems={'center'}>
      <Text color="white" fontSize={'24px'}>
        404 Not Found
      </Text>
      <Button onClick={() => navigate('/')}>Back to Home</Button>
    </VStack>
  );
};

const CallBackScreen = props => {
  let { type } = useParams();

  let navigate = useNavigate();
  const store = useContext(MainStore);

  let access_token = new URLSearchParams(window.location.search).get(
    `${type}_access_token`
  );

  useEffect(() => {
    attemptLogin();
  });

  const attemptLogin = () => {
    console.log('attempting', type);
    store
      ._googleFBLogin(type, access_token)
      .then(res => {
        if (res) navigate('/');
      })
      .catch(err => {
        // navigate('/');
      });
  };

  return (
    <Box p="10px">
      <Stack p="10px" backgroundColor="white">
        <Text fontWeight="bold">
          {`${type} access token:`.toLocaleUpperCase()}
        </Text>
        <Text>${access_token}</Text>
        <Button
          colorScheme="blue"
          onClick={() => {
            navigator.clipboard.writeText(access_token);

            store._globalToast({
              title: 'Access token copied!',
              description: '',
              status: 'success',
              isClosable: true,
            });
          }}
        >
          Copy Access Token
        </Button>
        <Button
          colorScheme="red"
          onClick={() => {
            attemptLogin();
          }}
        >
          Attempt Login API Call
        </Button>
      </Stack>
    </Box>
  );
};

const ComingSoonScreen = props => {
  return (
    <>
      <Header />
      <VStack h="70vh" justifyContent="center" alignItems="center">
        <Text fontFamily="press_start_2pregular" fontSize="32px" color="#fff">
          {props.title}
        </Text>
      </VStack>
      <Footer />
    </>
  );
};

const HowToPlayScreenMain = () => {
  return (
    <Stack>
      <Header />
      <Flex w="100%" pb="80px">
        <HowToPlayScreen />
      </Flex>
      <Footer />
    </Stack>
  );
};

const Login = () => {
  let navigate = useNavigate();
  return (
    <Box>
      <Text>Login</Text>
      <Button onClick={() => navigate(`/home`)}>Login</Button>
    </Box>
  );
};

const Home = () => {
  return (
    <Stack>
      <Text>Home</Text>
    </Stack>
  );
};

const SomeOther = observer(() => {
  const store = useContext(MainStore);

  useEffect(() => {
    console.log(store.people);
  }, [store.people]);

  useEffect(() => {
    store._getData({ endpoint: 'people' });
  }, []);

  return (
    <Box>
      <Button onClick={() => store._getData({ endpoint: 'people' })}>
        Get Data
      </Button>
      <Text>{`Loaded: ${
        store.people ? store.people.name : 'Not Available'
      }`}</Text>
    </Box>
  );
});

const GlobalModal = observer(() => {
  const store = useContext(MainStore);

  return (
    <Modal isOpen={store.showModal} closeOnOverlayClick={false}>
      <ModalOverlay />
      <ModalContent bg="none" minW="100% !important">
        <Box m="0 auto" p="18px">
          <Box
            w="100%"
            h="50%"
            maxW="563px"
            minH="320px"
            backgroundColor="#F8CA4F"
            border="5px solid #000000"
            boxShadow="-8px 8px 0px #000000"
            borderRadius="40px"
          >
            {/* <Box
            pos="absolute"
            top="-90px"
            left="50%"
            transform="translateX(-50%)"
            w="148px"
            h="148px"
            backgroundColor="white"
            border="5px solid #6D1BE8"
            boxShadow="-5px 5px 0px #500BB8"
          >
            <VStack pt="16px">
              <Text>Weh</Text>
            </VStack>
          </Box> */}
            <HStack pt="20px" px="30px">
              <Spacer />
              <Box
                cursor="pointer"
                _hover={{
                  transform: 'scale(0.98)',
                }}
                _active={{
                  transform: 'scale(0.95)',
                }}
                onClick={() => {
                  store._toggleModal({
                    value: false,
                    title: '',
                    description: '',
                  });
                }}
                style={{ filter: 'grayscale(100%)' }}
              >
                <Image src={CloseModal} />
              </Box>
            </HStack>
            <VStack p="30px">
              <Text
                fontWeight="400"
                fontFamily="press_start_2pregular"
                fontSize="26px"
                color="#000000"
              >
                {store.modalProps.title}
              </Text>
              <Container>
                <Text> {store.modalProps.description}</Text>
              </Container>

              <Box
                mt="30px !important"
                w="100%"
                textAlign="center"
                background="#6D1BE8"
                boxShadow="0px 8px 0px #4C12A4"
                borderRadius="8px"
                px="30px"
                py="20px"
                cursor="pointer"
                _hover={{
                  transform: 'scale(0.95)',
                }}
                _active={{
                  transform: 'scale(0.92)',
                }}
                onClick={() => {
                  store._toggleModal({
                    value: false,
                    title: '',
                    description: '',
                  });
                }}
              >
                <Text
                  fontFamily="press_start_2pregular"
                  color="#fff"
                  fontSize="20px"
                >
                  Okay
                </Text>
              </Box>
            </VStack>
          </Box>
        </Box>
      </ModalContent>
    </Modal>
  );
});

export default App;
