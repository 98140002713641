import React, { useContext, useEffect, useState, useRef } from 'react';
import {
  ChakraProvider,
  Box,
  Text,
  Link,
  VStack,
  Code,
  Grid,
  theme,
  Button,
  Stack,
  Spacer,
  SimpleGrid,
  HStack,
  Flex,
  Center,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  TabIndicator,
  FormControl,
  FormLabel,
  FormErrorMessage,
  FormHelperText,
  Input,
  Tag,
  TagLabel,
  TagLeftIcon,
  TagRightIcon,
  TagCloseButton,
  Wrap,
  WrapItem,
  Icon,
  IconButton,
  Image,
  InputGroup,
  InputRightElement,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Select,
  Checkbox,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  AlertDialogCloseButton,
  useDisclosure,
} from '@chakra-ui/react';

import { MdSettings } from 'react-icons/md';
import { BsFillHeartFill, BsQuestionSquare } from 'react-icons/bs';
import { FaCoins } from 'react-icons/fa';

// import HorizontalScroll from 'react-horizontal-scrolling';

import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route, useNavigate } from 'react-router-dom';
// import your route components too

import { observer } from 'mobx-react-lite';

import MainStore from '../stores/MainStore';

import Header from './Header';
import Footer from './Footer';

import Prize from '../assets/box_closed.png';
import PrizeOpen from '../assets/box_open.png';

import Logo from '../assets/logo.png';
import Heart from '../assets/heart.png';
import HeartEmpty from '../assets/heart_empty.png';
import Tokens from '../assets/token.png';
import RightArrow from '../assets/right_arrow.png';

import Flatpickr from 'react-flatpickr';
import moment from 'moment';

const AccountScreen = () => {
  const store = useContext(MainStore);
  let navigate = useNavigate();

  const [showDelete, setShowDelete] = useState(false);

  const [menu] = useState([
    'Account',
    'Profile',
    'History',
    'Interests',
    'Address',
    'Delete Account',
    'Logout',
  ]);
  const [selected, setSelected] = useState(0);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleClick = index => {
    switch (menu[index]) {
      case 'Delete Account':
        setShowDelete(true);
        break;
      case 'Logout':
        store._logout().then(res => navigate('/'));
        break;
      default:
        setSelected(index);
    }
  };

  useEffect(() => {}, [selected]);

  return (
    <Stack>
      <Header />
      <Flex color="white" mt="40px !important" mx="8px !important">
        <Box flex="1" display={{ base: 'none', md: 'block' }}>
          <Box
            w="270px"
            m="0 auto"
            border="1px solid white"
            borderRadius="20px"
          >
            <Stack p="20px" spacing="20px">
              {menu.map((label, index) => {
                return (
                  <HStack key={`l${index}`} cursor="pointer">
                    <Image
                      src={RightArrow}
                      mr="10px"
                      visibility={index === selected ? 'visible' : 'hidden'}
                    />
                    <Text
                      fontFamily="press_start_2pregular"
                      fontSize="16px"
                      onClick={() => {
                        handleClick(index);
                      }}
                    >
                      {label}
                    </Text>
                  </HStack>
                );
              })}
            </Stack>
          </Box>
          <Center w="250px" h="250px" backgroundColor="#FFF4B7" m="20px auto">
            <Text color="black">250x250 AD PLACEMENT</Text>
          </Center>
        </Box>
        <Box flex="4" px="20px">
          <Box display={{ base: 'block', md: 'none' }} my="20px">
            <Box mb="5px">
              <Text>Menu</Text>
            </Box>
            <Select
              backgroundColor="white"
              color="black"
              onChange={({ target: { value } }) => handleClick(parseInt(value))}
            >
              {menu.map((label, index) => {
                return (
                  <option key={`o${index}`} value={index}>
                    {label}
                  </option>
                );
              })}
            </Select>
          </Box>
          <Tabs index={selected}>
            <TabPanels>
              <TabPanel>
                <AccountTab />
              </TabPanel>
              <TabPanel>
                <ProfileTab />
              </TabPanel>
              <TabPanel>
                <HistoryTab />
              </TabPanel>
              <TabPanel>
                <SelectTopicsTab />
              </TabPanel>
              <TabPanel>
                <AddressTab />
              </TabPanel>
            </TabPanels>
          </Tabs>
        </Box>
      </Flex>
      <Footer />
      <AlertDelete isOpen={showDelete} onClose={() => setShowDelete(false)} />
    </Stack>
  );
};

const AccountTab = () => {
  const store = useContext(MainStore);

  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('*****');
  const [password2, setPassword2] = useState('');
  const [showPassword, togglePassword] = useState(false);
  const [showPassword2, togglePassword2] = useState(false);

  const [editMode, setEditMode] = useState(false);

  useEffect(() => {
    setEmail(store.userData ? store.userData.email : 'N/A');
  });

  const startEditMode = value => {
    if (value) {
      setPassword('');
      setPassword('');
    } else {
      setPassword('*****');
    }
    setEditMode(value);
  };

  const changePassword = () => {
    if (password === '') {
      store._globalToast({
        title: 'Password is required.',
        description: 'Please enter a password',
        status: 'error',
        isClosable: true,
      });
      return;
    } else if (password.length < 8) {
      store._globalToast({
        title: 'Password is too short.',
        description: 'Please use 8 characters or more',
        status: 'error',
        isClosable: true,
      });
      return;
    } else if (!store._checkUppercase(password)) {
      store._globalToast({
        title: 'Password is invalid',
        description: 'Please enter at least one uppercase character',
        status: 'error',
        isClosable: true,
      });
      return;
    } else if (!store._checkLowercase(password)) {
      store._globalToast({
        title: 'Password is invalid',
        description: 'Please enter at least one lower character',
        status: 'error',
        isClosable: true,
      });
      return;
    } else if (!store._checkHasNumber(password)) {
      store._globalToast({
        title: 'Password is invalid',
        description: 'Please enter at least one number',
        status: 'error',
        isClosable: true,
      });
      return;
    } else if (password !== password2) {
      store._globalToast({
        title: 'Password is invalid',
        description: 'Passwords do not match',
        status: 'error',
        isClosable: true,
      });
      return;
    }

    //change password
    setLoading(true);

    store
      ._updateUser({
        password: password,
        password_confirmation: password2,
      })
      .then(res => {
        setLoading(false);
        store._globalToast({
          title: 'Password updated',
          description: '',
          status: 'success',
          isClosable: true,
        });
        startEditMode(false);
      });

    // setTimeout(() => {
    //   setLoading(false);
    //   store._globalToast({
    //     title: 'Password updated',
    //     description: '',
    //     status: 'success',
    //     isClosable: true,
    //   });
    //   startEditMode(false);
    // }, 2000);
  };

  return (
    <Stack spacing="20px">
      <Text
        fontFamily="press_start_2pregular"
        color="#fff"
        fontSize={{ base: '20px', md: '30px' }}
        textShadow="-3px 3px 0px rgba(0, 0, 0, 0.35);"
        textAlign={{ base: 'center', md: 'left' }}
      >
        Account
      </Text>
      <FormControl mt="40px !important">
        <FormLabel fontFamily="nunitobold">Email address</FormLabel>
        <Input
          readOnly={true}
          background="#fff"
          color="#000"
          type="email"
          value={email}
          // value={store.userData ? store.userData.email : 'N/A'}
          // onChange={e => setEmail(e.target.value)}
        />
      </FormControl>
      <FormControl>
        <FormLabel fontFamily="nunitobold">
          {editMode ? 'New Password' : 'Password'}
        </FormLabel>
        <InputGroup size="md">
          <Input
            readOnly={!editMode}
            background="#fff"
            color="#000"
            type={showPassword ? 'text' : 'password'}
            value={password}
            onChange={e => setPassword(e.target.value)}
          />
          <InputRightElement width="80px" display={editMode ? 'flex' : 'none'}>
            <Button
              colorScheme="blue"
              size="sm"
              onClick={() => togglePassword(!showPassword)}
            >
              {showPassword ? 'Hide' : 'Show'}
            </Button>
          </InputRightElement>
        </InputGroup>
      </FormControl>
      {!editMode ? (
        <Box
          textAlign="center"
          mt="60px !important"
          background="#FF6BE8"
          boxShadow="0px 8px 0px #BB3FA8"
          borderRadius="8px"
          px="40px"
          py="16px"
          cursor="pointer"
          _hover={{
            transform: 'scale(0.98)',
          }}
          _active={{
            transform: 'scale(0.95)',
          }}
          onClick={() => startEditMode(true)}
        >
          <Text fontFamily="press_start_2pregular" color="#fff">
            Change Password
          </Text>
        </Box>
      ) : (
        <>
          <FormControl>
            <FormLabel fontFamily="nunitobold">Re-type Password</FormLabel>
            <InputGroup size="md">
              <Input
                background="#fff"
                color="#000"
                type={showPassword2 ? 'text' : 'password'}
                value={password2}
                onChange={e => setPassword2(e.target.value)}
              />
              <InputRightElement width="80px">
                <Button
                  colorScheme="blue"
                  size="sm"
                  onClick={() => togglePassword2(!showPassword2)}
                >
                  {showPassword2 ? 'Hide' : 'Show'}
                </Button>
              </InputRightElement>
            </InputGroup>
            <FormHelperText color="#fff">• 8 characters minimum</FormHelperText>
            <FormHelperText color="#fff">
              • One lowercase character
            </FormHelperText>
            <FormHelperText color="#fff">
              • One uppercase character
            </FormHelperText>
            <FormHelperText color="#fff">• One number</FormHelperText>
          </FormControl>
          <HStack>
            <Box
              pointerEvents={loading ? 'none' : 'initial'}
              mt="60px !important"
              // background="#FF6BE8"
              // boxShadow="0px 8px 0px #BB3FA8"
              borderRadius="8px"
              px="40px"
              py="16px"
              cursor="pointer"
              _hover={{
                transform: 'scale(0.98)',
              }}
              _active={{
                transform: 'scale(0.95)',
              }}
              onClick={() => startEditMode(false)}
            >
              <Text fontFamily="press_start_2pregular" color="#fff">
                Cancel
              </Text>
            </Box>
            <Box
              pointerEvents={loading ? 'none' : 'initial'}
              mt="60px !important"
              background="#FF6BE8"
              boxShadow="0px 8px 0px #BB3FA8"
              borderRadius="8px"
              px="40px"
              py="16px"
              cursor="pointer"
              _hover={{
                transform: 'scale(0.98)',
              }}
              _active={{
                transform: 'scale(0.95)',
              }}
              onClick={() => changePassword()}
            >
              <Text fontFamily="press_start_2pregular" color="#fff">
                Save
              </Text>
            </Box>
          </HStack>
        </>
      )}
    </Stack>
  );
};

const ProfileTab = () => {
  const store = useContext(MainStore);

  const [loading, setLoading] = useState(false);
  const [username, setUsername] = useState('');
  const [firstname, setFirstname] = useState('');
  const [lastname, setLastname] = useState('');
  const [birthdate, setBirthdate] = useState(new Date());
  const [mobile, setMobile] = useState('');

  useEffect(() => {
    if (store.userData) {
      setUsername(store.userData.username);
      setFirstname(store.userData.first_name);
      setLastname(store.userData.last_name);
      setBirthdate(store.userData.birth_date ?? 'N/A');
      setMobile(store.userData.mobile_number ?? 'N/A');
    }
  }, [store.userData]);

  const validate = () => {
    let ctr = 0;

    //check for blanks
    if (username === '') ctr++;
    if (firstname === '') ctr++;
    if (lastname === '') ctr++;
    if (mobile === '') ctr++;

    if (ctr > 0) {
      store._globalToast({
        title: 'Incomplete details',
        description: 'Please fill up the form',
        status: 'error',
        isClosable: true,
      });
      return;
    }

    if (!store._checkMobile(mobile)) {
      store._globalToast({
        title: 'Invalid phone number',
        description: 'Please input 09XXXXXXXXX',
        status: 'error',
        isClosable: true,
      });
      return;
    }

    const parseDate = moment(birthdate).format('YYYY-MM-DD');

    const age = moment().diff(parseDate, 'years');

    if (birthdate === 'N/A') {
      store._globalToast({
        title: 'Invalid birthdate',
        description: 'Please select your birthdate',
        status: 'error',
        isClosable: true,
      });
      return;
    }

    console.log(age);
    if (age < 18) {
      store._globalToast({
        title: 'You must be at least 18 years old',
        description: '',
        status: 'error',
        isClosable: true,
      });
      return;
    }

    setLoading(true);

    store
      ._updateUser({
        first_name: firstname,
        last_name: lastname,
        birth_date: parseDate,
        mobile_number: mobile,
      })
      .then(res => {
        console.log(res);
        setLoading(false);
        store._globalToast({
          title: 'Profile updated',
          description: '',
          status: 'success',
          isClosable: true,
        });
      })
      .catch(error => {
        setLoading(false);
        store._globalToast({
          title: 'Profile update failed',
          description: '',
          status: 'error',
          isClosable: true,
        });
      });

    // setTimeout(() => {
    //   setLoading(false);
    //   store._globalToast({
    //     title: 'Profile updated',
    //     description: '',
    //     status: 'success',
    //     isClosable: true,
    //   });
    // }, 2000);

    // checkDetails({
    //   username,
    //   firstname,
    //   lastname,
    //   birthdate: parseDate,
    //   mobile,
    // });
  };

  return (
    <Stack spacing="20px">
      <Text
        fontFamily="press_start_2pregular"
        color="#fff"
        fontSize={{ base: '20px', md: '30px' }}
        textShadow="-3px 3px 0px rgba(0, 0, 0, 0.35);"
        textAlign={{ base: 'center', md: 'left' }}
      >
        Profile
      </Text>
      <FormControl>
        <FormLabel fontFamily="nunitobold">Username</FormLabel>
        <FormHelperText color="#fff" textAlign="left" mb="14px">
          Your name that will appear in the website
        </FormHelperText>
        <Input
          background="#fff"
          color="#000"
          type="text"
          placeholder="juandelacruz"
          value={username}
          // onChange={e => setUsername(e.target.value)}
          disabled
        />
      </FormControl>
      <FormControl>
        <FormLabel fontFamily="nunitobold">First Name</FormLabel>
        <Input
          background="#fff"
          color="#000"
          type="text"
          placeholder="Juan"
          value={firstname}
          onChange={e => setFirstname(e.target.value)}
        />
      </FormControl>
      <FormControl>
        <FormLabel fontFamily="nunitobold">Last Name</FormLabel>
        <Input
          background="#fff"
          color="#000"
          type="text"
          placeholder="Dela Cruz"
          value={lastname}
          onChange={e => setLastname(e.target.value)}
        />
      </FormControl>
      <FormControl>
        <FormLabel fontFamily="nunitobold">Birth date</FormLabel>
        <Flatpickr
          options={{
            altInput: true,
            altFormat: 'F j, Y',
            dateFormat: 'Y-m-d',
          }}
          value={birthdate}
          onChange={([date]) => {
            setBirthdate(date);
          }}
          render={({ defaultValue, value, ...props }, ref) => {
            return (
              <Input
                background="#fff"
                color="#000"
                type="text"
                placeholder="Select Date"
                {...props}
                ref={ref}
              />
            );
          }}
        />
      </FormControl>
      <FormControl>
        <FormLabel fontFamily="nunitobold">Mobile number</FormLabel>
        <Input
          background="#fff"
          color="#000"
          type="tel"
          placeholder="0905738882"
          value={mobile}
          onChange={e => setMobile(e.target.value)}
          maxLength="11"
        />
      </FormControl>
      <Box
        pointerEvents={loading ? 'none' : 'initial'}
        mt="60px !important"
        background="#FF6BE8"
        boxShadow="0px 8px 0px #BB3FA8"
        borderRadius="8px"
        px="40px"
        py="16px"
        cursor="pointer"
        textAlign="center"
        _hover={{
          transform: 'scale(0.98)',
        }}
        _active={{
          transform: 'scale(0.95)',
        }}
        onClick={() => validate()}
      >
        <Text fontFamily="press_start_2pregular" color="#fff">
          {loading ? 'Validating...' : 'Save'}
        </Text>
      </Box>
    </Stack>
  );
};

const HistoryTab = () => {
  let navigate = useNavigate();

  const store = useContext(MainStore);
  const [history, setHistory] = useState([]);

  useEffect(() => {
    store._getHistory().then(res => {
      setHistory(res.data);
    });
  }, []);

  return (
    <Stack spacing="20px">
      <Text
        fontFamily="press_start_2pregular"
        color="#fff"
        fontSize={{ base: '20px', md: '30px' }}
        textShadow="-3px 3px 0px rgba(0, 0, 0, 0.35);"
        textAlign={{ base: 'center', md: 'left' }}
      >
        History
      </Text>
      {history.map((h, index) => {
        return (
          <Stack spacing="20px" key={`history${index}`}>
            <Text>
              {`You won ${h.prize} ${h.type === 'token' ? 'Tokens' : 'Pesos'}`}
            </Text>

            <HStack>
              <Text>{`${
                moment().calendar().length === 10
                  ? moment().calendar()
                  : moment().format('LLLL')
              }`}</Text>
              {/* <Text fontSize="14px">{`${moment(h.opened_at).fromNow()}`}</Text> */}
              {/* <Text>|</Text> */}
              {/* <Text>{`${moment(h.opened_at).fromNow()}`}</Text> */}
            </HStack>

            {h.type !== 'token' && (
              <>
                {!h.claimed_at ? (
                  <Box
                    // pointerEvents={loading ? 'none' : 'initial'}
                    w="264px"
                    background="#FF6BE8"
                    boxShadow="0px 8px 0px #BB3FA8"
                    borderRadius="8px"
                    px="40px"
                    py="16px"
                    cursor="pointer"
                    _hover={{
                      transform: 'scale(0.98)',
                    }}
                    _active={{
                      transform: 'scale(0.95)',
                    }}
                    onClick={() => {
                      navigate('/claim', { state: { prizeID: h.id } });
                    }}
                  >
                    <Text fontFamily="press_start_2pregular" color="#fff">
                      {`Claim Prize`}
                    </Text>
                  </Box>
                ) : (
                  <Stack>
                    <Text>{`Claimed on: ${moment(h.claimed_at).format(
                      'LLLL'
                    )}`}</Text>
                    <Text>{`Bank: ${h.claimed_bank}`}</Text>
                    <Text>{`Account Name: ${h.claimed_bank_account_name}`}</Text>
                    <Text>{`Account Number: ${h.claimed_bank_account_number}`}</Text>
                    <Text>{`Email: ${h.claimed_email}`}</Text>
                  </Stack>
                )}
              </>
            )}

            <hr style={{ marginTop: '30px' }} />
          </Stack>
        );
      })}
    </Stack>
  );
};

const SelectTopicsTab = ({ loading }) => {
  let navigate = useNavigate();

  const store = useContext(MainStore);

  const topicsLoaded = useRef(false);
  const [topics, setTopics] = useState([]);
  const [lock, setLock] = useState(false);

  useEffect(() => {
    store._getInterests().then(res => {
      //process interests
      const interests = res.map(i => {
        return { id: i.id, title: i.name, selected: false };
      });
      setTopics(interests);
    });
  }, []);

  useEffect(() => {
    if (!topicsLoaded.current && topics.length > 0) {
      topicsLoaded.current = true;
      updateTopics();
    }
  }, [topics]);

  useEffect(() => {
    if (topics.length > 0 && store.userData) {
      updateTopics();
    }
  }, [store.userData]);

  const updateTopics = () => {
    const interests = [...store.userData.interests];
    const tempTopics = [...topics];

    interests.map(i => {
      const index = tempTopics.findIndex(topic => topic.title === i);
      if (tempTopics.length > 0) tempTopics[index].selected = true;
    });

    setTopics(tempTopics);
  };

  const submitPayload = value => {
    store
      ._addInterests({ interests: value.filter(t => t.selected) })
      .then(res => {
        store._globalToast({
          title: 'Topics updated',
          description: '',
          status: 'success',
          isClosable: true,
        });
      });
  };

  const clickTopic = async index => {
    let tempTopics = [...topics];

    tempTopics[index].selected = !topics[index].selected;

    if (topics.filter(t => t.selected).length > 3) {
      //revert
      tempTopics[index].selected = !topics[index].selected;
      store._globalToast({
        title: 'Error',
        description: 'You can only choose up to 3 topics.',
        status: 'error',
        isClosable: true,
      });

      return;
    }

    // check what it was set to
    // if (tempTopics[index].selected) {
    //   await store._addInterest({ interest_id: tempTopics[index].id });
    // } else {
    //   await store._deleteInterest({ interest_id: tempTopics[index].id });
    // }

    setTopics(tempTopics);
  };

  return (
    <VStack pt="40px" spacing="20px">
      <Text
        fontFamily="press_start_2pregular"
        color="#fff"
        fontSize="20px"
        // lineHeight="36px"
        textShadow="-3px 3px 0px rgba(0, 0, 0, 0.35);"
      >
        Choose 3 topics that you like
      </Text>
      <Text>{`Customize the game and prizes according to your interests`}</Text>
      <br />
      <Wrap spacing="20px" justify="center" align="center">
        {topics.map((topic, index) => {
          return (
            <WrapItem key={`topic${index}`}>
              <Box
                background={topic.selected ? '#FFD510' : '#fff'}
                borderRadius="20px"
                color="#000"
                cursor="pointer"
                px="20px"
                py="12px"
                onClick={() => {
                  clickTopic(index);
                }}
              >
                <HStack>
                  <Icon as={MdSettings} />
                  <Text>{topic.title}</Text>
                </HStack>
              </Box>
            </WrapItem>
          );
        })}
      </Wrap>
      <Box
        pointerEvents={loading ? 'none' : 'initial'}
        mt="60px !important"
        background="#FF6BE8"
        boxShadow="0px 8px 0px #BB3FA8"
        borderRadius="8px"
        px="40px"
        py="16px"
        cursor="pointer"
        _hover={{
          transform: 'scale(0.98)',
        }}
        _active={{
          transform: 'scale(0.95)',
        }}
        onClick={() => {
          submitPayload(topics);
        }}
      >
        <Text fontFamily="press_start_2pregular" color="#fff">
          {loading ? 'Submitting...' : 'Save'}
        </Text>
      </Box>
    </VStack>
  );
};

const AddressTab = () => {
  const store = useContext(MainStore);

  const [loading, setLoading] = useState(false);

  const [number, setNumber] = useState('');
  const [street, setStreet] = useState('');
  const [subdivision, setSubdivision] = useState('');
  const [building, setBuilding] = useState('');
  const [province, setProvince] = useState('');
  const [city, setCity] = useState('');
  const [barangay, setBarangay] = useState('');
  const [zip, setZip] = useState('');

  const [primary, setPrimary] = useState(false);

  useEffect(() => {
    store._getAddress().then(res => {
      if (res.data.length > 0) {
        const temp = res.data[0];
        //set data
        setNumber(temp.unit);
        setStreet(temp.street);
        setSubdivision(temp.place);
        setBuilding(temp.building);
        setProvince(temp.province);
        setCity(temp.city);
        setBarangay(temp.barangay);
      }
    });
  }, []);

  const validate = () => {
    let ctr = 0;

    //check for blanks
    if (number === '') ctr++;
    if (street === '') ctr++;
    // if (subdivision === '') ctr++;
    // if (building === '') ctr++;
    if (province === '') ctr++;
    if (city === '') ctr++;
    if (barangay === '') ctr++;

    if (ctr > 0) {
      store._globalToast({
        title: 'Incomplete details',
        description: 'Please fill up the form',
        status: 'error',
        isClosable: true,
      });
      return;
    }

    setLoading(true);

    var data = {
      number,
      street,
      subdivision,
      building,
      province,
      city,
      barangay,
    };

    console.log(data);

    store._saveAddress(data).then(res => {
      setLoading(false);

      if (res) {
        const temp = res.data;
        //set data
        setNumber(temp.unit);
        setStreet(temp.street);
        setSubdivision(temp.place);
        setBuilding(temp.building);
        setProvince(temp.province);
        setCity(temp.city);
        setBarangay(temp.barangay);
      }

      store._globalToast({
        title: 'Address updated',
        description: '',
        status: 'success',
        isClosable: true,
      });
    });
  };

  return (
    <Stack spacing="20px">
      <Text
        fontFamily="press_start_2pregular"
        color="#fff"
        fontSize={{ base: '20px', md: '30px' }}
        textShadow="-3px 3px 0px rgba(0, 0, 0, 0.35);"
        textAlign={{ base: 'center', md: 'left' }}
      >
        Address
      </Text>
      <FormControl>
        <FormLabel fontFamily="nunitobold">
          House/Floor/Room # (required)
        </FormLabel>
        <Input
          background="#fff"
          color="#000"
          type="text"
          placeholder="  House/Floor/Room #"
          value={number}
          onChange={e => setNumber(e.target.value)}
        />
      </FormControl>
      <FormControl>
        <FormLabel fontFamily="nunitobold">Street (required)</FormLabel>
        <Input
          background="#fff"
          color="#000"
          type="text"
          placeholder="Street"
          value={street}
          onChange={e => setStreet(e.target.value)}
        />
      </FormControl>
      <FormControl>
        <FormLabel fontFamily="nunitobold">
          Subdivision / Apartment / Village (optional)
        </FormLabel>
        <Input
          background="#fff"
          color="#000"
          type="text"
          placeholder="Subdivision / Apartment / Village"
          value={subdivision}
          onChange={e => setSubdivision(e.target.value)}
        />
      </FormControl>
      <FormControl>
        <FormLabel fontFamily="nunitobold">Building</FormLabel>
        <Input
          background="#fff"
          color="#000"
          type="text"
          placeholder="Building"
          value={building}
          onChange={e => setBuilding(e.target.value)}
        />
      </FormControl>
      <FormControl>
        <FormLabel fontFamily="nunitobold">Province (required)</FormLabel>
        <Input
          background="#fff"
          color="#000"
          type="text"
          placeholder="Province"
          value={province}
          onChange={e => setProvince(e.target.value)}
        />
      </FormControl>
      <FormControl>
        <FormLabel fontFamily="nunitobold">
          City / Municipality (required)
        </FormLabel>
        <Input
          background="#fff"
          color="#000"
          type="text"
          placeholder="City / Municipality"
          value={city}
          onChange={e => setCity(e.target.value)}
        />
      </FormControl>
      <FormControl>
        <FormLabel fontFamily="nunitobold">Barangay (required)</FormLabel>
        <Input
          background="#fff"
          color="#000"
          type="text"
          placeholder="Barangay"
          value={barangay}
          onChange={e => setBarangay(e.target.value)}
        />
      </FormControl>
      {/* <HStack>
        <Checkbox onChange={e => setPrimary(e.target.checked)}></Checkbox>
        <Text>Set as my primary address</Text>
      </HStack> */}
      <Box
        pointerEvents={loading ? 'none' : 'initial'}
        mt="60px !important"
        background="#FF6BE8"
        boxShadow="0px 8px 0px #BB3FA8"
        borderRadius="8px"
        px="40px"
        py="16px"
        cursor="pointer"
        textAlign="center"
        _hover={{
          transform: 'scale(0.98)',
        }}
        _active={{
          transform: 'scale(0.95)',
        }}
        onClick={() => validate()}
      >
        <Text fontFamily="press_start_2pregular" color="#fff">
          {loading ? 'Validating...' : 'Save'}
        </Text>
      </Box>
    </Stack>
  );
};

function AlertDelete({ isOpen, onClose }) {
  const store = useContext(MainStore);
  const cancelRef = React.useRef();

  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);

  return (
    <AlertDialog
      isOpen={isOpen}
      leastDestructiveRef={cancelRef}
      onClose={onClose}
      closeOnOverlayClick={!loading}
    >
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            Delete Account
          </AlertDialogHeader>

          <AlertDialogBody>
            Are you sure? You can't undo this action afterwards.
          </AlertDialogBody>

          <AlertDialogFooter>
            <Button isLoading={loading} ref={cancelRef} onClick={onClose}>
              Cancel
            </Button>
            <Button
              isLoading={loading}
              colorScheme="red"
              onClick={() => {
                setLoading(true);
                store._deleteAccount().then(res => {
                  setLoading(false);
                  store._globalToast({
                    title: 'Account deleted.',
                    description: '',
                    status: 'error',
                    isClosable: true,
                  });
                  onClose();
                  store._logout().then(res => navigate('/'));
                });
              }}
              ml={3}
            >
              Delete
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
}

export default AccountScreen;
