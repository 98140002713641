import React, { useContext, useEffect, useState, useRef } from 'react';
import {
  ChakraProvider,
  Box,
  Text,
  Link,
  VStack,
  Code,
  Grid,
  theme,
  Button,
  Stack,
  Spacer,
  SimpleGrid,
  HStack,
  Flex,
  Center,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  FormControl,
  FormLabel,
  FormErrorMessage,
  FormHelperText,
  Input,
  Tag,
  TagLabel,
  TagLeftIcon,
  TagRightIcon,
  TagCloseButton,
  Wrap,
  WrapItem,
  Icon,
  IconButton,
  Image,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuItemOption,
  MenuGroup,
  MenuOptionGroup,
  MenuDivider,
  useBreakpointValue,
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
} from '@chakra-ui/react';

// import HorizontalScroll from 'react-horizontal-scrolling';

import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route, useNavigate } from 'react-router-dom';
// import your route components too

import { observer } from 'mobx-react-lite';

import MainStore from '../stores/MainStore';

import Logo from '../assets/logo.png';
import Heart from '../assets/heart.png';
import HeartEmpty from '../assets/heart_empty.png';
import Tokens from '../assets/token.png';

import moment from 'moment';

const Header = observer(props => {
  const store = useContext(MainStore);
  let navigate = useNavigate();

  var timer = useRef();
  const [countDown, setCountDown] = useState(0);

  const isMobile = useBreakpointValue({
    base: 'base',
    md: 'md',
    lg: 'lg',
  });

  const [menuOpen, setMenuOpen] = useState(false);

  useEffect(() => {
    console.log(isMobile);
  }, [isMobile]);

  const setHearts = () => {
    if (!store.userData) {
      return '';
    }

    const display = [];
    for (let i = 0; i < 3; i++) {
      if (i < store.userData.hearts) {
        display.push(<Image key={`h${i}`} src={Heart} />);
      } else {
        display.push(<Image key={`h${i}`} src={HeartEmpty} />);
      }
    }
    return display;
  };

  const getTime = () => {
    const seconds = countDown;

    const result2 = new Date(seconds * 1000).toISOString().slice(11, 19);

    return result2;
  };

  useEffect(() => {
    timer = setInterval(() => {
      if (store.gameData) {
        //compute timer
        const a = moment(store.gameData.end);
        const b = moment().utc(false);

        var time = a.diff(b, 'seconds');

        setCountDown(time);
      }
    }, 1000);

    return () => {
      clearInterval(timer.current);
    };
  }, []);

  return (
    <Stack>
      <HStack pt="15px" px="40px" mb="10px" mt="20px">
        <Image
          cursor="pointer"
          h={{ base: '55px', md: '85px' }}
          src={Logo}
          onClick={() => {
            store.userData ? navigate('/home') : navigate('/');
          }}
        />
        {!props.hideMenu && (
          <>
            {isMobile === 'base' ? (
              <HStack flex="1">
                <Spacer />
                <Box
                  background="#4C23B3"
                  boxShadow="0px 8px 0px #2A0A5A"
                  borderRadius="8px"
                  px="30px"
                  py="10px"
                  cursor="pointer"
                  _hover={{
                    transform: 'scale(0.98)',
                  }}
                  _active={{
                    transform: 'scale(0.95)',
                  }}
                  onClick={() => {
                    setMenuOpen(true);
                  }}
                >
                  <Text
                    fontFamily="press_start_2pregular"
                    color="#fff"
                    whiteSpace={'nowrap'}
                  >
                    Menu
                  </Text>
                </Box>
                <MobileMenu
                  isOpen={menuOpen}
                  onClose={() => {
                    setMenuOpen(false);
                  }}
                />
              </HStack>
            ) : (
              // <Stack ml="40px !important" spacing="20px" w="100% !important">
              <Stack flex="1">
                <HStack
                  ml="5px !important"
                  spacing={{ md: '10px', lg: '40px' }}
                >
                  <Button
                    fontFamily="press_start_2pregular"
                    color="#fff"
                    cursor="pointer"
                    variant="link"
                    onClick={() => navigate('/home')}
                  >
                    Home
                  </Button>
                  <Button
                    fontFamily="press_start_2pregular"
                    color="#fff"
                    cursor="pointer"
                    variant="link"
                    onClick={() => navigate('/marketplace')}
                  >
                    Marketplace
                  </Button>
                  <Button
                    fontFamily="press_start_2pregular"
                    color="#fff"
                    cursor="pointer"
                    variant="link"
                    onClick={() => navigate('/how-to-play')}
                  >
                    How to Play
                  </Button>
                  <Button
                    fontFamily="press_start_2pregular"
                    color="#fff"
                    cursor="pointer"
                    variant="link"
                    onClick={() => navigate('/account')}
                  >
                    Account
                  </Button>
                  <Spacer />
                  <Box>
                    <Menu>
                      <MenuButton
                        as={Button}
                        variant="link"
                        fontFamily="nunitoregular"
                        fontSize="20px"
                        color="#FFDC0D"
                        fontStyle="italic"
                        _hover={{ color: 'gray.400' }}
                        _expanded={{ color: '#FFDC0D' }}
                      >
                        {`${store.userData && store.userData.username}`}
                      </MenuButton>
                      <MenuList>
                        <MenuItem
                          onClick={() =>
                            store._logout().then(res => navigate('/'))
                          }
                        >
                          Logout
                        </MenuItem>
                      </MenuList>
                    </Menu>
                  </Box>
                </HStack>
                <HStack spacing="40px">
                  {!props.hidePlayNow && (
                    <Box
                      // mt="60px !important"
                      background="#FF6BE8"
                      boxShadow="0px 8px 0px #BB3FA8"
                      borderRadius="8px"
                      px="30px"
                      py="10px"
                      cursor="pointer"
                      _hover={{
                        transform: 'scale(0.98)',
                      }}
                      _active={{
                        transform: 'scale(0.95)',
                      }}
                      onClick={() => {
                        navigate('/play');
                        // regOrPlay();
                      }}
                    >
                      <Text
                        fontFamily="press_start_2pregular"
                        color="#fff"
                        whiteSpace={'nowrap'}
                      >
                        PLAY NOW
                      </Text>
                    </Box>
                  )}
                  <Spacer />
                  <Box
                    backgroundColor="#4C23B3"
                    color="#fff"
                    borderRadius="25px"
                    p="8px 29px"
                    minW="308px"
                  >
                    <HStack>
                      {setHearts()}
                      <Text
                        ml="20px !important"
                        fontFamily="press_start_2pregular"
                        color="#fff"
                      >
                        {getTime()}
                      </Text>
                    </HStack>
                  </Box>

                  <Box
                    backgroundColor="#4C23B3"
                    color="#fff"
                    borderRadius="25px"
                    p="8px 29px"
                  >
                    <HStack>
                      <Image src={Tokens} />
                      <Text fontFamily="press_start_2pregular" color="#fff">
                        {`${store.userData && store.userData.tokens}`}
                      </Text>
                    </HStack>
                  </Box>
                </HStack>
              </Stack>
            )}
          </>
        )}
      </HStack>
      {!props.hideMenu && (
        <HStack
          display={{ base: 'flex', md: 'none' }}
          justifyContent={'center'}
          px="2px"
        >
          <Box
            backgroundColor="#4C23B3"
            color="#fff"
            borderRadius="25px"
            p="8px 8px"
            minW="264px"
          >
            <HStack>
              {setHearts()}
              <Text
                ml="10px !important"
                fontFamily="press_start_2pregular"
                color="#fff"
              >
                {getTime()}
              </Text>
            </HStack>
          </Box>
          <Box
            backgroundColor="#4C23B3"
            color="#fff"
            borderRadius="25px"
            p="8px 12px"
          >
            <HStack>
              <Image src={Tokens} />
              <Text fontFamily="press_start_2pregular" color="#fff">
                {`${store.userData && store.userData.tokens}`}
              </Text>
            </HStack>
          </Box>
        </HStack>
      )}
    </Stack>
  );
});

function MobileMenu({ isOpen, onClose }) {
  const store = useContext(MainStore);
  let navigate = useNavigate();

  return (
    <Drawer placement={'top'} onClose={onClose} isOpen={isOpen}>
      <DrawerOverlay />
      <DrawerContent background={'#4C23B3'}>
        <DrawerHeader>
          <VStack>
            <Image
              cursor="pointer"
              h={{ base: '55px', md: '85px' }}
              src={Logo}
              onClick={() => {
                store.userData ? navigate('/home') : navigate('/');
              }}
            />
          </VStack>
        </DrawerHeader>
        <DrawerBody>
          <VStack spacing="20px" py="20px">
            <Button
              fontFamily="press_start_2pregular"
              color="#fff"
              cursor="pointer"
              variant="link"
              onClick={() => navigate('/home')}
            >
              Home
            </Button>
            <Button
              fontFamily="press_start_2pregular"
              color="#fff"
              cursor="pointer"
              variant="link"
              onClick={() => navigate('/marketplace')}
            >
              Marketplace
            </Button>
            <Button
              fontFamily="press_start_2pregular"
              color="#fff"
              cursor="pointer"
              variant="link"
              onClick={() => navigate('/how-to-play')}
            >
              How to Play
            </Button>
            <Button
              fontFamily="press_start_2pregular"
              color="#fff"
              cursor="pointer"
              variant="link"
              onClick={() => navigate('/account')}
            >
              Account
            </Button>
            <Box
              background="#FF6BE8"
              boxShadow="0px 8px 0px #BB3FA8"
              borderRadius="8px"
              px="30px"
              py="10px"
              cursor="pointer"
              _hover={{
                transform: 'scale(0.98)',
              }}
              _active={{
                transform: 'scale(0.95)',
              }}
              onClick={() => {
                navigate('/play');
                // regOrPlay();
              }}
            >
              <Text
                fontFamily="press_start_2pregular"
                color="#fff"
                whiteSpace={'nowrap'}
              >
                PLAY NOW
              </Text>
            </Box>
          </VStack>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
}

export default Header;
