import { createContext } from 'react';
import { makeAutoObservable, runInAction, toJS } from 'mobx';
import { createStandaloneToast } from '@chakra-ui/toast';
import axios from 'axios';
import qs from 'qs';
import moment from 'moment';

const { toast } = createStandaloneToast();

//will need to put this on .env eventually

class Main {
  // baseURL = 'https://lucky-pix-z15uw98n09iptb7i.cadavonahomelab.com';

  baseURL = process.env.REACT_APP_BACKEND_URL;
  buildDate = process.env.REACT_APP_BUILD_DATE;

  loginData = null;
  userData = null;
  gameData = null;
  homeData = null;

  secondsPassed = 1;

  people = null;

  showModal = false;
  modalProps = {
    title: 'title',
    description: 'description',
  };

  constructor() {
    makeAutoObservable(this);

    if (process.env.REACT_APP_BUILD_DATE !== 'local') {
      console.log = function () {};
    }

    //check if logged in
    const checkLogged = JSON.parse(
      window.localStorage.getItem('luckypix.login')
    );

    if (checkLogged) {
      this.loginData = checkLogged;
      this._getUser();
      this._getGame();
      this._getHome();
    }

    // console.log(checkLogged);
  }

  _toggleModal = ({ value, title, description }) => {
    console.log('whyyyy');
    this.showModal = value;
    this.modalProps = { title, description };
  };

  _googleLogin = () => {
    window.open(`${this.baseURL}/auth/google/redirect`, '_self');
  };

  _fbLogin = () => {
    window.open(`${this.baseURL}/auth/facebook/redirect`, '_self');
  };

  _globalToast = params => {
    // toast.closeAll();
    // toast(params);

    console.log(params);

    this._toggleModal({
      value: true,
      title: params.title,
      description: params.description,
    });
  };

  _validateEmail = email => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  _checkUppercase = str => {
    for (var i = 0; i < str.length; i++) {
      if (
        str.charAt(i) == str.charAt(i).toUpperCase() &&
        str.charAt(i).match(/[a-z]/i)
      ) {
        return true;
      }
    }
    return false;
  };

  _checkLowercase = str => {
    for (var i = 0; i < str.length; i++) {
      if (
        str.charAt(i) == str.charAt(i).toLowerCase() &&
        str.charAt(i).match(/[A-Z]/i)
      ) {
        return true;
      }
    }
    return false;
  };

  _checkHasNumber = str => {
    var hasNumber = /\d/;
    return hasNumber.test(str);
  };

  _checkMobile = str => {
    return String(str).match(
      /(\+?\d{2}?\s?\d{3}\s?\d{3}\s?\d{4})|([0]\d{3}\s?\d{3}\s?\d{4})/g
    );
  };

  _register = params => {
    const that = this;

    var data = new FormData();
    data.append('email', params.email);
    data.append('password', params.password);
    data.append('password_confirmation', params.password);
    data.append('username', params.username);
    data.append('first_name', params.firstname);
    data.append('last_name', params.lastname);
    data.append('mobile_number', params.mobile);
    data.append('birth_date', params.birthdate);
    if (params.interests) {
      params.interests.map(i => {
        data.append('interests[]', i.id);
      });
    }

    var config = {
      method: 'post',
      url: this.baseURL + '/api/auth/register',
      headers: {
        Accept: 'application/json',
      },
      data: data,
    };

    return new Promise((resolve, reject) => {
      axios(config)
        .then(function (response) {
          console.log(JSON.stringify(response.data));

          resolve(true);

          that._globalToast({
            title: 'Registration successful',
            description: '',
            status: 'success',
            isClosable: true,
          });
        })
        .catch(function (error) {
          console.log(error.response.data);

          let errorMsgs = error.response.data.errors;

          for (const key in errorMsgs) {
            if (errorMsgs.hasOwnProperty(key)) {
              // console.log(`${key}: ${user[key]}`);
              that._globalToast({
                title: error.response.data.message,
                description: errorMsgs[key][0],
                status: 'error',
                isClosable: true,
              });
            }
          }

          reject(error);
        });
    });
  };

  _login = params => {
    const that = this;

    var data = new FormData();
    data.append('email', params.email);
    data.append('password', params.password);

    var config = {
      method: 'post',
      url: this.baseURL + '/api/auth/login',
      headers: {
        Accept: 'application/json',
      },
      data: data,
    };

    return new Promise((resolve, reject) => {
      axios(config)
        .then(function (response) {
          console.log(response.data);

          // console.log(response.data.email_verified_at);
          // if (!response.data.email_verified_at) {
          if (false) {
            console.log('Unverified');

            that._globalToast({
              title: 'Email unverified',
              description: 'Please verify your email before logging in',
              status: 'error',
              isClosable: true,
            });

            reject(false);
            return;
          }

          window.localStorage.setItem(
            'luckypix.login',
            JSON.stringify(response.data)
          );

          that.loginData = response.data;

          that._getUser();

          resolve(true);

          that._globalToast({
            title: 'Login successful',
            description: '',
            status: 'success',
            isClosable: true,
          });
        })
        .catch(function (error) {
          console.log(error);
          // console.log(error.response.data);

          if (error && error.code === 'ERR_NETWORK') {
            console.log('huh');
            that._globalToast({
              title: 'Network Error',
              description: '',
              status: 'error',
              isClosable: true,
            });
            reject(error);
            return;
          }

          that._globalToast({
            // title: error.response.data.message,
            title: 'Your email or password is incorrect',
            description: '',
            status: 'error',
            isClosable: true,
          });

          reject(error);
        });
    });
  };

  _forgotPassword = email => {
    let that = this;

    var data = new FormData();
    data.append('email', email);

    var config = {
      method: 'post',
      url: `${this.baseURL}/api/auth/forgot-password`,
      headers: {
        Accept: 'application/json',
      },
      data: data,
    };

    return new Promise((resolve, reject) => {
      axios(config)
        .then(function (response) {
          console.log(JSON.stringify(response.data));

          resolve(true);

          that._globalToast({
            title: response.data.message,
            description: '',
            status: 'success',
            isClosable: true,
          });
        })
        .catch(function (error) {
          console.log(error);

          that._globalToast({
            title: error.response.data ? error.response.data.message : 'error',
            description: '',
            status: 'error',
            isClosable: true,
          });

          reject(error);
        });
    });
  };

  _googleFBLogin = (type, access_token) => {
    let that = this;

    var data = new FormData();
    data.append('access_token', access_token);

    var config = {
      method: 'post',
      url: `${this.baseURL}/api/auth/login-with-${type}`,
      headers: {
        Accept: 'application/json',
      },
      data: data,
    };

    return new Promise((resolve, reject) => {
      axios(config)
        .then(function (response) {
          console.log(JSON.stringify(response.data));

          window.localStorage.setItem(
            'luckypix.login',
            JSON.stringify(response.data)
          );

          that.loginData = response.data;

          that._getUser();

          resolve(true);

          let message = '';

          if (type === 'google') {
            message = 'Google Login successful';
          } else {
            message = 'Facebook Login successful';
          }

          that._globalToast({
            title: message,
            description: '',
            status: 'success',
            isClosable: true,
          });
        })
        .catch(function (error) {
          console.log(error);

          that._globalToast({
            title: error.response.data ? error.response.data.message : 'error',
            description: '',
            status: 'error',
            isClosable: true,
          });

          reject(error);
        });
    });
  };

  _logout = () => {
    if (!this.userData) {
      return Promise.resolve();
    }

    let that = this;

    var config = {
      method: 'post',
      url: this.baseURL + '/api/auth/logout',
      headers: {
        Authorization: 'Bearer ' + this.loginData.access_token,
      },
    };

    return new Promise((resolve, reject) => {
      axios(config)
        .then(function (response) {
          console.log(JSON.stringify(response.data));

          runInAction(() => {
            window.localStorage.removeItem('luckypix.login');

            that.loginData = null;
            that.userData = null;
            resolve();
          });

          that._globalToast({
            title: 'Logout successful',
            description: '',
            status: 'success',
            isClosable: true,
          });
        })
        .catch(function (error) {
          console.log(error);
        });
    });
  };

  _getUser = () => {
    const that = this;

    var config = {
      method: 'get',
      url: this.baseURL + '/api/user',
      headers: {
        Accept: 'application/json',
        Authorization: 'Bearer ' + this.loginData.access_token,
      },
    };

    axios(config)
      .then(function (response) {
        // console.log(response.data);
        runInAction(() => {
          that.userData = response.data;
        });
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  _getGame = () => {
    const that = this;

    var config = {
      method: 'get',
      url: this.baseURL + '/api/game',
      headers: {
        Accept: 'application/json',
        Authorization: 'Bearer ' + this.loginData.access_token,
      },
    };

    return new Promise((resolve, reject) => {
      axios(config)
        .then(function (response) {
          // console.log(response.data);
          runInAction(() => {
            that.gameData = response.data;
            resolve(true);
          });
        })
        .catch(function (error) {
          console.log(error);
        });
    });
  };

  _openSlot = params => {
    const that = this;

    var data = qs.stringify({
      slot: params.slot,
    });

    var config = {
      method: 'post',
      url: this.baseURL + '/api/game',
      headers: {
        Accept: 'application/json',
        Authorization: 'Bearer ' + this.loginData.access_token,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      data: data,
    };

    return new Promise((resolve, reject) => {
      axios(config)
        .then(function (response) {
          // console.log(response.data);
          resolve(response.data);

          that._getUser();
          that._getGame();
          that._getHome();
        })
        .catch(function (error) {
          console.log(error);

          reject(error);
        });
    });
  };

  _getHome = () => {
    const that = this;

    var config = {
      method: 'get',
      url: this.baseURL + '/api/home',
      headers: {
        Accept: 'application/json',
      },
    };

    return new Promise((resolve, reject) => {
      axios(config)
        .then(function (response) {
          that.homeData = response.data;

          runInAction(() => {
            resolve(response.data);
          });
        })
        .catch(function (error) {
          console.log(error);
        });
    });
  };

  _getInterests = () => {
    const that = this;

    var config = {
      method: 'get',
      url: this.baseURL + '/api/interests',
      headers: {
        Accept: 'application/json',
      },
    };

    return new Promise((resolve, reject) => {
      axios(config)
        .then(function (response) {
          // console.log(response.data);
          runInAction(() => {
            resolve(response.data);
          });
        })
        .catch(function (error) {
          console.log(error);
        });
    });
  };

  _addInterests = async params => {
    const that = this;

    const { interests } = params;

    console.log(params.interests);

    await this._deleteInterests();

    console.log('interests deleted');

    const promises = [];

    for (let i = 0; i < interests.length; i++) {
      const topic = interests[i];

      let data = new FormData();
      data.append('interest_id', topic.id);

      var config = {
        method: 'post',
        url: this.baseURL + '/api/user/interests',
        headers: {
          Accept: 'application/json',
          Authorization: 'Bearer ' + this.loginData.access_token,
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        data: data,
      };

      promises.push(axios(config));
    }

    return Promise.all(promises).then(results => {
      console.log(results);
      that._getUser();
    });
  };

  _deleteInterests = params => {
    const that = this;

    var config = {
      method: 'delete',
      url: this.baseURL + `/api/user/interests`,
      headers: {
        Accept: 'application/json',
        Authorization: 'Bearer ' + this.loginData.access_token,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };

    return new Promise((resolve, reject) => {
      axios(config)
        .then(function (response) {
          runInAction(() => {
            resolve(response.data);
          });
        })
        .catch(function (error) {
          console.log(error);
        });
    });
  };

  _getData = params => {
    const that = this;

    return new Promise((resolve, reject) => {
      axios
        .get(`https://swapi.dev/api/${params.endpoint}/${this.secondsPassed}`)
        .then(function (response) {
          // handle success
          console.log(response);

          that.people = response.data;

          that._globalToast({
            title: 'Data Loaded!',
            description: 'Get Success!',
            status: 'success',
            duration: 9000,
            isClosable: true,
          });

          console.log(toJS(that.people));
        })
        .catch(function (error) {
          // handle error
          console.log(error);

          if (error.response) {
            that._globalToast({
              title: 'An error occurred.',
              description: error.response.data.detail,
              status: 'error',
              duration: 9000,
              isClosable: true,
            });
          }
        });
    });
  };

  _getHistory = () => {
    const that = this;

    var config = {
      method: 'get',
      url: this.baseURL + '/api/user/history',
      headers: {
        Accept: 'application/json',
        Authorization: 'Bearer ' + this.loginData.access_token,
      },
    };

    return new Promise((resolve, reject) => {
      axios(config)
        .then(function (response) {
          runInAction(() => {
            resolve(response.data);
          });
        })
        .catch(function (error) {
          console.log(error);
        });
    });
  };

  _updateUser = props => {
    const that = this;

    props.username = this.userData.username;

    // let data = qs.stringify({
    //   username: 'axelray',
    //   email: 'joserizal@example.com',
    //   password: 'secret1234',
    //   password_confirmation: 'secret1234',
    //   first_name: '',
    //   last_name: '',
    //   birth_date: '2000-12-31',
    //   mobile_number: '09087654321',
    // });

    let config = {
      method: 'patch',
      maxBodyLength: Infinity,
      url: this.baseURL + '/api/user',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded',
        Authorization: 'Bearer ' + this.loginData.access_token,
      },
      data: qs.stringify(props),
    };

    return new Promise((resolve, reject) => {
      axios(config)
        .then(function (response) {
          that._getUser();

          runInAction(() => {
            resolve(response.data);
          });
        })
        .catch(function (error) {
          console.log(error);
          reject(error);
        });
    });
  };

  _getAddress = () => {
    const that = this;

    var config = {
      method: 'get',
      url: this.baseURL + '/api/user/addresses',
      headers: {
        Accept: 'application/json',
        Authorization: 'Bearer ' + this.loginData.access_token,
      },
    };

    return axios(config)
      .then(function (response) {
        return response;
      })
      .catch(function (error) {
        return error;
      });
  };

  _saveAddress = async params => {
    const that = this;

    var headers = {
      Accept: 'application/json',
      Authorization: 'Bearer ' + this.loginData.access_token,
    };

    //first check if there is an address
    let address;
    try {
      address = await axios({
        method: 'get',
        url: this.baseURL + '/api/user/addresses',
        headers,
      });
    } catch (e) {
      return Promise.resolve(false);
    }

    let data = qs.stringify({
      unit: params.number,
      building: params.building,
      place: params.subdivision,
      street: params.street,
      province: params.province,
      city: params.city,
      barangay: params.barangay,
      zip_code: 0,
      is_primary: 1,
    });

    if (address) {
      if (address.data.length <= 0) {
        //add a new one

        address = await axios({
          method: 'post',
          url: this.baseURL + '/api/user/addresses',
          headers,
          data,
        });

        console.log('new', address);
      } else {
        //get the ID and update

        address = await axios({
          method: 'patch',
          url: this.baseURL + '/api/user/addresses/' + address.data[0].id,
          headers,
          data,
        });

        console.log('update', address);
      }
    }

    return Promise.resolve(address);
  };

  _getProducts = () => {
    const that = this;

    var config = {
      method: 'get',
      url: this.baseURL + '/api/products',
      headers: {
        Accept: 'application/json',
        Authorization: 'Bearer ' + this.loginData.access_token,
      },
    };

    return axios(config)
      .then(function (response) {
        return response;
      })
      .catch(function (error) {
        return error;
      });
  };

  _getCart = () => {
    const that = this;

    var config = {
      method: 'get',
      url: this.baseURL + '/api/cart',
      headers: {
        Accept: 'application/json',
        Authorization: 'Bearer ' + this.loginData.access_token,
      },
    };

    return axios(config)
      .then(function (response) {
        return response;
      })
      .catch(function (error) {
        return error;
      });
  };

  _updateCartItem = ({ itemID, qty }) => {
    const that = this;

    let data = qs.stringify({
      quantity: String(qty),
    });

    var config = {
      method: 'patch',
      url: this.baseURL + '/api/cart/products/' + itemID,
      headers: {
        Accept: 'application/json',
        Authorization: 'Bearer ' + this.loginData.access_token,
      },
      data,
    };

    return axios(config)
      .then(function (response) {
        return response;
      })
      .catch(function (error) {
        return error;
      });
  };

  _addCartItem = ({ itemID, qty }) => {
    const that = this;

    let data = qs.stringify({
      product_id: itemID,
      quantity: parseInt(qty),
    });

    var config = {
      method: 'post',
      url: this.baseURL + '/api/cart/products',
      headers: {
        Accept: 'application/json',
        Authorization: 'Bearer ' + this.loginData.access_token,
      },
      data,
    };

    return axios(config)
      .then(function (response) {
        return response;
      })
      .catch(function (error) {
        return error;
      });
  };

  _deleteItem = ({ itemID }) => {
    const that = this;

    var config = {
      method: 'delete',
      url: this.baseURL + '/api/cart/products/' + itemID,
      headers: {
        Accept: 'application/json',
        Authorization: 'Bearer ' + this.loginData.access_token,
      },
    };

    return axios(config)
      .then(function (response) {
        return response;
      })
      .catch(function (error) {
        return error;
      });
  };

  _deleteCart = () => {
    const that = this;

    var config = {
      method: 'delete',
      url: this.baseURL + '/api/cart/',
      headers: {
        Accept: 'application/json',
        Authorization: 'Bearer ' + this.loginData.access_token,
      },
    };

    return axios(config)
      .then(function (response) {
        return response;
      })
      .catch(function (error) {
        return error;
      });
  };

  _submitOrder = ({ cart, address }) => {
    const that = this;

    console.log(cart, address);

    let data = {
      'shipping_address[unit]': address.number,
      'shipping_address[building]': address.building,
      'shipping_address[place]': address.subdivision,
      'shipping_address[street]': address.street,
      'shipping_address[province]': address.province,
      'shipping_address[city]': address.city,
      'shipping_address[barangay]': address.barangay,
      'shipping_address[zip_code]': '0',
    };

    for (let i = 0; i < cart.length; i++) {
      const item = cart[i];
      data[`products[${i}][product_id]`] = item.product_id;
      data[`products[${i}][quantity]`] = item.quantity;
    }

    var config = {
      method: 'post',
      url: this.baseURL + '/api/orders',
      headers: {
        Accept: 'application/json',
        Authorization: 'Bearer ' + this.loginData.access_token,
      },
      data: qs.stringify(data),
    };

    return axios(config)
      .then(function (response) {
        return response;
      })
      .catch(function (error) {
        return error;
      });
  };

  _claimPrize = ({ prizeID, bank, accountName, accountNumber, email }) => {
    const that = this;

    let data = new FormData();
    data.append('bank', bank);
    data.append('bank_account_name', accountName);
    data.append('bank_account_number', accountNumber);
    data.append('email', email);

    var config = {
      method: 'post',
      url: this.baseURL + '/api/user/prizes/' + prizeID,
      headers: {
        Accept: 'application/json',
        Authorization: 'Bearer ' + this.loginData.access_token,
      },
      data,
    };

    return axios(config)
      .then(function (response) {
        return response;
      })
      .catch(function (error) {
        return error;
      });
  };

  _deleteAccount = () => {
    const that = this;

    var config = {
      method: 'delete',
      url: this.baseURL + '/api/user',
      headers: {
        Accept: 'application/json',
        Authorization: 'Bearer ' + this.loginData.access_token,
      },
    };

    return axios(config)
      .then(function (response) {
        that.loginData = null;
        that.userData = null;

        window.localStorage.removeItem('luckypix.login');

        return response;
      })
      .catch(function (error) {
        return error;
      });
  };
}

export default createContext(new Main());
