import React, { useContext, useEffect, useState } from 'react';
import {
  ChakraProvider,
  Box,
  Text,
  Link,
  VStack,
  Code,
  Grid,
  theme,
  Button,
  Stack,
  Spacer,
  SimpleGrid,
  HStack,
  Flex,
  Center,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  FormControl,
  FormLabel,
  FormErrorMessage,
  FormHelperText,
  Input,
  Tag,
  TagLabel,
  TagLeftIcon,
  TagRightIcon,
  TagCloseButton,
  Wrap,
  WrapItem,
  Icon,
  IconButton,
  Image,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Select,
} from '@chakra-ui/react';

import { BsFillHeartFill, BsQuestionSquare } from 'react-icons/bs';
import { FaCoins } from 'react-icons/fa';

// import HorizontalScroll from 'react-horizontal-scrolling';

import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route, useNavigate } from 'react-router-dom';
// import your route components too

import { observer } from 'mobx-react-lite';

import MainStore from '../stores/MainStore';

import Header from './Header';
import Footer from './Footer';

import Prize from '../assets/box_closed.png';
import PrizeOpen from '../assets/box_open.png';

import Logo from '../assets/logo.png';
import Heart from '../assets/heart.png';
import HeartEmpty from '../assets/heart_empty.png';
import Tokens from '../assets/token.png';
import RightArrow from '../assets/right_arrow.png';

const FAQScreen = () => {
  let navigate = useNavigate();

  const [menu] = useState([
    'Game',
    'Payment',
    'Shipping',
    'Return Policy',
    'Account',
  ]);
  const [selected, setSelected] = useState(0);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleClick = index => {
    const anchorName = '#' + menu[index].replace(/ /g, '-');
    const anchor = document.querySelector(anchorName);
    anchor.scrollIntoView({ behavior: 'smooth' });
    setSelected(index);
  };

  useEffect(() => {}, [selected]);

  return (
    <Stack>
      <Header />
      <Flex color="white" mt="40px !important" mx="8px !important">
        <Box flex="1" display={{ base: 'none', md: 'block' }}>
          <Box
            w="270px"
            m="0 auto"
            border="1px solid white"
            borderRadius="20px"
          >
            <Stack p="20px" spacing="20px">
              {menu.map((label, index) => {
                return (
                  <HStack key={`l${index}`} cursor="pointer">
                    <Image
                      src={RightArrow}
                      mr="10px"
                      visibility={index === selected ? 'visible' : 'hidden'}
                    />
                    <Text
                      fontFamily="press_start_2pregular"
                      fontSize="16px"
                      onClick={() => {
                        handleClick(index);
                      }}
                    >
                      {label}
                    </Text>
                  </HStack>
                );
              })}
            </Stack>
          </Box>
          <Center w="250px" h="250px" backgroundColor="#FFF4B7" m="20px auto">
            <Text color="black">250x250 AD PLACEMENT</Text>
          </Center>
        </Box>
        <Box flex="4" px="20px">
          <Text
            fontFamily="press_start_2pregular"
            color="#fff"
            fontSize={{ base: '20px', md: '30px' }}
            textShadow="-3px 3px 0px rgba(0, 0, 0, 0.35);"
            textAlign={{ base: 'center', md: 'left' }}
          >
            Frequently Asked Questions
          </Text>
          <Box display={{ base: 'block', md: 'none' }} my="20px">
            <Box mb="5px">
              <Text>Choose a topic</Text>
            </Box>
            <Select
              backgroundColor="white"
              color="black"
              onChange={({ target: { value } }) => handleClick(parseInt(value))}
            >
              {menu.map((label, index) => {
                return (
                  <option key={`o${index}`} value={index}>
                    {label}
                  </option>
                );
              })}
            </Select>
          </Box>
          {menu.map((label, index) => {
            return (
              <Box key={`m${index}`} mt="20px" id={label.replace(/ /g, '-')}>
                <Box px="10px">
                  <Text color="#fff" fontSize="36px">
                    {label}
                  </Text>
                </Box>
                <Accordion>
                  {[0, 0, 0, 0, 0].map((b, index) => {
                    return (
                      <AccordionItem
                        key={`a${index}`}
                        borderTop={index > 0 ? '' : 'none'}
                        borderColor="rgba(255,255,255,0.25)"
                      >
                        <h2>
                          <AccordionButton py="20px" _expanded={{ py: '10px' }}>
                            <Box flex="1" textAlign="left">
                              <Text fontWeight="bold" fontSize="20px">
                                {`Section ${index} title`}
                              </Text>
                            </Box>
                            <AccordionIcon
                              color="purple"
                              w="40px"
                              h="40px"
                              bg="linear-gradient(147.19deg, #F2BE57 -3.74%, #F3CC77 104.38%)"
                              borderRadius="100px"
                            />
                          </AccordionButton>
                        </h2>
                        <AccordionPanel pb="30px">
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit, sed do eiusmod tempor incididunt ut labore et
                          dolore magna aliqua. Ut enim ad minim veniam, quis
                          nostrud exercitation ullamco laboris nisi ut aliquip
                          ex ea commodo consequat.
                        </AccordionPanel>
                      </AccordionItem>
                    );
                  })}
                </Accordion>
              </Box>
            );
          })}
        </Box>
      </Flex>
      <Footer />
    </Stack>
  );
};

export default FAQScreen;
