import React, { useContext, useEffect, useState } from 'react';
import {
  ChakraProvider,
  Box,
  Text,
  Link,
  VStack,
  Code,
  Grid,
  theme,
  Button,
  Stack,
  Spacer,
  SimpleGrid,
  HStack,
  Flex,
  Center,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  FormControl,
  FormLabel,
  FormErrorMessage,
  FormHelperText,
  Input,
  Tag,
  TagLabel,
  TagLeftIcon,
  TagRightIcon,
  TagCloseButton,
  Wrap,
  WrapItem,
  Icon,
  Image,
  Checkbox,
  CheckboxGroup,
  InputGroup,
  InputRightElement,
} from '@chakra-ui/react';

import { MdSettings } from 'react-icons/md';
import { FaFacebook, FaTwitter, FaGoogle } from 'react-icons/fa';

// import HorizontalScroll from 'react-horizontal-scrolling';

import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route, useNavigate } from 'react-router-dom';
// import your route components too

import { observer } from 'mobx-react-lite';

import MainStore from '../stores/MainStore';

import Header from './Header';
import Footer from './Footer';

import Logo from '../assets/logo.png';

// import 'flatpickr/dist/themes/material_green.css';
import 'flatpickr/dist/themes/airbnb.css';
import Flatpickr from 'react-flatpickr';
import moment from 'moment';

const RegisterScreen = () => {
  let navigate = useNavigate();
  const store = useContext(MainStore);

  const [tabIndex, setTabIndex] = React.useState(0);
  const [loading, setLoading] = useState(false);

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [username, setUsername] = useState('');
  const [firstname, setFirstname] = useState('');
  const [lastname, setLastname] = useState('');
  const [birthdate, setBirthdate] = useState('');
  const [mobile, setMobile] = useState('');
  const [payload, setPayload] = useState();

  useEffect(() => {
    // window.scrollTo(0, 0);
  });

  const checkEmailPass = params => {
    console.log('Check Email and Password', params);
    setEmail(params.email);
    setPassword(params.password);
    setTabIndex(1);
  };

  const checkDetails = params => {
    console.log('Check Details', params);

    const data = {
      email,
      password,
      ...params,
    };

    // setPayload(data);

    setLoading(true);

    // setTabIndex(2);

    //if all fields are valid, attempt to register
    store
      ._register(data)
      .then(res => {
        setLoading(false);
        setTabIndex(2);
      })
      .catch(err => {
        setLoading(false);
        console.log(err);
        // setTabIndex(2);
      });

    // return;

    // setLoading(true);

    // //if all fields are valid, attempt to register
    // store
    //   ._register({
    //     email,
    //     password,
    //     ...params,
    //   })
    //   .then(res => {
    //     setLoading(false);
    //   })
    //   .catch(err => {
    //     setLoading(false);
    //   });
  };

  const handleTabsChange = index => {
    setTabIndex(index);
  };

  const submitPayload = topics => {
    setLoading(true);

    //include interests to payload
    console.log(topics);

    const data = { ...payload };
    data.interests = topics.filter(t => t.selected);

    if (data.interests.length > 3) {
      store._globalToast({
        title: 'Error',
        description: 'You can only choose up to 3 topics.',
        status: 'error',
        isClosable: true,
      });

      setLoading(false);

      return;
    }

    //if all fields are valid, attempt to register
    store
      ._register(data)
      .then(res => {
        setLoading(false);
        setTabIndex(3);
      })
      .catch(err => {
        setLoading(false);
      });
  };

  return (
    <Stack>
      <Header hideMenu={true} />
      <Flex w="100%" pb="80px">
        <Center
          m="20px"
          backgroundColor="#FFF4B7"
          minW="160px"
          h="600px"
          display={{ base: 'none', md: 'flex' }}
        >
          <Box>
            <Text textAlign="center">160 x 600</Text>
            <Text textAlign="center">AD PLACEMENT</Text>
          </Box>
        </Center>
        <VStack w="100%" minH="500px">
          <Box w="100%" maxW="728px" color="#fff">
            <Tabs
              index={tabIndex}
              onChange={handleTabsChange}
              isFitted
              variant="unstyled"
              w="100%"
              align="center"
            >
              <TabList>
                <Tab
                  _selected={{
                    borderBottom: '2px solid',
                    borderColor: '#FFD510',
                    color: '#FFD510',
                  }}
                >
                  {/* Step 1 */}
                </Tab>
                <Tab
                  _selected={{
                    borderBottom: '2px solid',
                    borderColor: '#FFD510',
                    color: '#FFD510',
                  }}
                >
                  {/* Step 2 */}
                </Tab>
                <Tab
                  _selected={{
                    borderBottom: '2px solid',
                    borderColor: '#FFD510',
                    color: '#FFD510',
                  }}
                >
                  {/* Step 3 */}
                </Tab>
              </TabList>

              <TabPanels>
                <TabPanel>
                  <Step1
                    setTabIndex={setTabIndex}
                    checkEmailPass={checkEmailPass}
                  />
                </TabPanel>
                <TabPanel>
                  <Step2
                    setTabIndex={setTabIndex}
                    loading={loading}
                    checkDetails={checkDetails}
                    submitPayload={submitPayload}
                  />
                </TabPanel>
                {/* <TabPanel>
                  <Step3
                    setTabIndex={setTabIndex}
                    loading={loading}
                    submitPayload={submitPayload}
                  />
                </TabPanel> */}
                <TabPanel>
                  <Step4 setTabIndex={setTabIndex} email={email} />
                </TabPanel>
              </TabPanels>
            </Tabs>
          </Box>
        </VStack>
        <Center
          m="20px"
          backgroundColor="#FFF4B7"
          minW="160px"
          h="600px"
          display={{ base: 'none', md: 'flex' }}
        >
          <Box>
            <Text textAlign="center">160 x 600</Text>
            <Text textAlign="center">AD PLACEMENT</Text>
          </Box>
        </Center>
      </Flex>
      <Footer />
    </Stack>
  );
};

const CreatAccountHeader = () => {
  let navigate = useNavigate();
  const store = useContext(MainStore);

  return (
    <VStack>
      <Text
        fontFamily="press_start_2pregular"
        color="#fff"
        fontSize="20px"
        textShadow="-3px 3px 0px rgba(0, 0, 0, 0.35);"
      >
        Create an account
      </Text>
      <HStack>
        <Text>Already have an account?</Text>
        <Button
          ml="10px !important"
          variant="link"
          color="#FFD510"
          fontFamily="press_start_2pregular"
          fontSize="10px"
          onClick={() => navigate('/login')}
          cursor="pointer"
        >
          Sign in
        </Button>
      </HStack>
      <Flex mt="30px !important" flexDir={{ base: 'column', sm: 'row' }}>
        <Button
          // disabled={true}
          w="306px"
          size="lg"
          background="#3B5998"
          borderRadius="20px"
          _hover={{ opacity: '0.8' }}
          leftIcon={<FaFacebook />}
          onClick={() => store._fbLogin()}
          m="4px"
        >
          Register with Facebook
        </Button>
        <Button
          // disabled={true}
          w="306px"
          size="lg"
          background="#F34A38"
          borderRadius="20px"
          _hover={{ opacity: '0.8' }}
          leftIcon={<FaGoogle />}
          onClick={() => store._googleLogin()}
          m="4px"
        >
          Register with Google
        </Button>
      </Flex>
      <HStack w="100%" textAlign="center" mt="30px !important">
        <hr style={{ width: '100%', opacity: '0.5' }} />
        <Text
          textAlign="center"
          ml="15px !important"
          mr="5px !important"
          whiteSpace="nowrap"
        >
          OR REGISTER WITH YOUR EMAIL
        </Text>
        <hr style={{ width: '100%', opacity: '0.5' }} />
      </HStack>
    </VStack>
  );
};

const Step1 = ({ setTabIndex, checkEmailPass }) => {
  let navigate = useNavigate();
  let store = useContext(MainStore);

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, togglePassword] = useState(false);

  const validate = () => {
    //check if blanks
    if (email === '') {
      store._globalToast({
        title: 'Email is required.',
        description: 'Please enter a valid email',
        status: 'error',
        isClosable: true,
      });
      return;
    } else if (!store._validateEmail(email)) {
      store._globalToast({
        title: 'Email is invalid.',
        description: 'Please enter a valid email',
        status: 'error',
        isClosable: true,
      });
      return;
    }

    if (password === '') {
      store._globalToast({
        title: 'Password is required.',
        description: 'Please enter a password',
        status: 'error',
        isClosable: true,
      });
      return;
    } else if (password.length < 8) {
      store._globalToast({
        title: 'Password is too short.',
        description: 'Please use 8 characters or more',
        status: 'error',
        isClosable: true,
      });
      return;
    } else if (!store._checkUppercase(password)) {
      store._globalToast({
        title: 'Password is invalid',
        description: 'Please enter at least one uppercase character',
        status: 'error',
        isClosable: true,
      });
      return;
    } else if (!store._checkLowercase(password)) {
      store._globalToast({
        title: 'Password is invalid',
        description: 'Please enter at least one lower character',
        status: 'error',
        isClosable: true,
      });
      return;
    } else if (!store._checkHasNumber(password)) {
      store._globalToast({
        title: 'Password is invalid',
        description: 'Please enter at least one number',
        status: 'error',
        isClosable: true,
      });
      return;
    }

    checkEmailPass({ email, password });
  };

  return (
    <VStack color="#fff" pt="40px" textAlign="left" spacing="20px">
      <CreatAccountHeader />
      <FormControl>
        <FormLabel fontFamily="nunitobold">Email address</FormLabel>
        <Input
          background="#fff"
          color="#000"
          type="email"
          value={email}
          onChange={e => setEmail(e.target.value)}
          required
        />
      </FormControl>
      <FormControl>
        <FormLabel fontFamily="nunitobold">Password</FormLabel>
        <InputGroup size="md">
          <Input
            background="#fff"
            color="#000"
            type={showPassword ? 'text' : 'password'}
            value={password}
            onChange={e => setPassword(e.target.value)}
          />
          <InputRightElement width="80px">
            <Button
              colorScheme="blue"
              size="sm"
              onClick={() => togglePassword(!showPassword)}
            >
              {showPassword ? 'Hide' : 'Show'}
            </Button>
          </InputRightElement>
        </InputGroup>
        <FormHelperText color="#fff">• 8 characters minimum</FormHelperText>
        <FormHelperText color="#fff">• One lowercase character</FormHelperText>
        <FormHelperText color="#fff">• One uppercase character</FormHelperText>
        <FormHelperText color="#fff">• One number</FormHelperText>
      </FormControl>

      <Box
        // pointerEvents={loading ? 'none' : 'initial'}
        type="submit"
        mt="60px !important"
        background="#FF6BE8"
        boxShadow="0px 8px 0px #BB3FA8"
        borderRadius="8px"
        px="40px"
        py="16px"
        cursor="pointer"
        _hover={{
          transform: 'scale(0.98)',
        }}
        _active={{
          transform: 'scale(0.95)',
        }}
        onClick={() => {
          validate();
        }}
      >
        <Text fontFamily="press_start_2pregular" color="#fff">
          Register
        </Text>
      </Box>
    </VStack>
  );
};

const Step2 = ({ setTabIndex, loading, checkDetails }) => {
  const store = useContext(MainStore);
  let navigate = useNavigate();

  const [username, setUsername] = useState('');
  const [firstname, setFirstname] = useState('');
  const [lastname, setLastname] = useState('');
  const [birthdate, setBirthdate] = useState(new Date());
  const [mobile, setMobile] = useState('');
  const [agree, setAgree] = useState(false);

  const validate = () => {
    let ctr = 0;

    //check for blanks
    if (username === '') ctr++;
    if (firstname === '') ctr++;
    if (lastname === '') ctr++;
    if (mobile === '') ctr++;

    if (ctr > 0) {
      store._globalToast({
        title: 'Incomplete details',
        description: 'Please fill up the form',
        status: 'error',
        isClosable: true,
      });
      return;
    }

    if (!store._checkMobile(mobile)) {
      store._globalToast({
        title: 'Invalid phone number',
        description: 'Please input 09XXXXXXXXX',
        status: 'error',
        isClosable: true,
      });
      return;
    }

    if (!agree) {
      store._globalToast({
        title: 'Error',
        description:
          'You must agree to the Terms of Use and Privacy Statement to continue.',
        status: 'error',
        isClosable: true,
      });
      return;
    }

    const parseDate = moment(birthdate).format('YYYY-MM-DD');

    checkDetails({
      username,
      firstname,
      lastname,
      birthdate: parseDate,
      mobile,
    });
  };

  return (
    <VStack pt="40px" spacing="20px">
      <CreatAccountHeader />
      <FormControl>
        <FormLabel fontFamily="nunitobold">Username</FormLabel>
        <FormHelperText color="#fff" textAlign="left" mb="14px">
          Your name that will appear in the website
        </FormHelperText>
        <Input
          background="#fff"
          color="#000"
          type="text"
          placeholder="juandelacruz"
          value={username}
          onChange={e => setUsername(e.target.value)}
        />
      </FormControl>
      <FormControl>
        <FormLabel fontFamily="nunitobold">First Name</FormLabel>
        <Input
          background="#fff"
          color="#000"
          type="text"
          placeholder="Juan"
          value={firstname}
          onChange={e => setFirstname(e.target.value)}
        />
      </FormControl>
      <FormControl>
        <FormLabel fontFamily="nunitobold">Last Name</FormLabel>
        <Input
          background="#fff"
          color="#000"
          type="text"
          placeholder="Dela Cruz"
          value={lastname}
          onChange={e => setLastname(e.target.value)}
        />
      </FormControl>
      <FormControl>
        <FormLabel fontFamily="nunitobold">Birth date</FormLabel>
        <Flatpickr
          options={{
            altInput: true,
            altFormat: 'F j, Y',
            dateFormat: 'Y-m-d',
          }}
          value={birthdate}
          onChange={([date]) => {
            setBirthdate(date);
          }}
          render={({ defaultValue, value, ...props }, ref) => {
            return (
              <Input
                background="#fff"
                color="#000"
                type="text"
                placeholder="Select Date"
                {...props}
                ref={ref}
              />
            );
          }}
        />
      </FormControl>
      <FormControl>
        <FormLabel fontFamily="nunitobold">Mobile number</FormLabel>
        <Input
          background="#fff"
          color="#000"
          type="tel"
          placeholder="0905738882"
          value={mobile}
          onChange={e => setMobile(e.target.value)}
          maxLength="11"
        />
      </FormControl>
      <Flex flexDir={{ base: 'column', sm: 'row' }}>
        <Checkbox
          m="0 auto"
          onChange={e => setAgree(e.target.checked)}
        ></Checkbox>
        <Text mx={{ base: '4px !important', sm: '0px' }}>I agree to the</Text>
        <Link
          mx={{ base: '4px !important', sm: '0px' }}
          color="#FFD510"
          onClick={() => navigate('/terms-and-conditions')}
        >
          Terms of Use
        </Link>
        <Text mx={{ base: '4px !important', sm: '0px' }}>and</Text>
        <Link
          mx={{ base: '4px !important', sm: '0px' }}
          color="#FFD510"
          onClick={() => navigate('/privacy-policy')}
        >
          Privacy Statement
        </Link>
      </Flex>

      <HStack>
        <Box
          pointerEvents={loading ? 'none' : 'initial'}
          mt="60px !important"
          background="#FF6BE8"
          boxShadow="0px 8px 0px #BB3FA8"
          borderRadius="8px"
          px="40px"
          py="16px"
          cursor="pointer"
          _hover={{
            transform: 'scale(0.98)',
          }}
          _active={{
            transform: 'scale(0.95)',
          }}
          onClick={() => setTabIndex()}
        >
          <Text fontFamily="press_start_2pregular" color="#fff">
            {`Back`}
          </Text>
        </Box>
        <Box
          pointerEvents={loading ? 'none' : 'initial'}
          mt="60px !important"
          background="#FF6BE8"
          boxShadow="0px 8px 0px #BB3FA8"
          borderRadius="8px"
          px="40px"
          py="16px"
          cursor="pointer"
          _hover={{
            transform: 'scale(0.98)',
          }}
          _active={{
            transform: 'scale(0.95)',
          }}
          onClick={() => validate()}
        >
          <Text fontFamily="press_start_2pregular" color="#fff">
            {loading ? 'Validating...' : 'Next'}
          </Text>
        </Box>
      </HStack>
    </VStack>
  );
};

const Step4 = ({ setTabIndex, email }) => {
  let navigate = useNavigate();

  return (
    <VStack pt="40px" spacing="20px">
      <Text
        fontFamily="press_start_2pregular"
        color="#fff"
        fontSize="20px"
        textShadow="-3px 3px 0px rgba(0, 0, 0, 0.35);"
      >
        Check your mailbox
      </Text>
      <br />
      <Text>{`We sent an email to [${email}]. You'll find a link that will sign you into Lucky Box.`}</Text>
      <br />
      <Text>{`This may take up to 5 minutes, or the message may have been sent to your spam folder`}</Text>
      <Box
        mt="60px !important"
        background="#FF6BE8"
        boxShadow="0px 8px 0px #BB3FA8"
        borderRadius="8px"
        px="40px"
        py="16px"
        cursor="pointer"
        _hover={{
          transform: 'scale(0.98)',
        }}
        _active={{
          transform: 'scale(0.95)',
        }}
        onClick={() => navigate('/')}
      >
        <Text fontFamily="press_start_2pregular" color="#fff">
          Back to Menu
        </Text>
      </Box>
      <VStack mt="60px !important">
        <Text>Entered the wrong email address?</Text>
        <Button
          variant="link"
          color="#FFD510"
          onClick={() => {
            setTabIndex(0);
          }}
        >
          Register again
        </Button>
      </VStack>
    </VStack>
  );
};

const Step3 = ({ loading, submitPayload }) => {
  let navigate = useNavigate();

  const store = useContext(MainStore);

  const [topics, setTopics] = useState([]);

  useEffect(() => {
    store._getInterests().then(res => {
      console.log('Interests', res);
      //process interests
      const interests = res.map(i => {
        return { id: i.id, title: i.name, selected: false };
      });
      setTopics(interests);
    });
  }, []);

  const clickTopic = index => {
    console.log(index);
    let tempTopics = [...topics];
    tempTopics[index].selected = !topics[index].selected;

    setTopics(tempTopics);
  };

  return (
    <VStack pt="40px" spacing="20px">
      <Text
        fontFamily="press_start_2pregular"
        color="#fff"
        fontSize="20px"
        // lineHeight="36px"
        textShadow="-3px 3px 0px rgba(0, 0, 0, 0.35);"
      >
        Choose 3 topics that you like
      </Text>
      <Text>{`Customize the game and prizes according to your interests`}</Text>
      <br />
      <Wrap spacing="20px" justify="center" align="center">
        {topics.map((topic, index) => {
          return (
            <WrapItem key={`topic${index}`}>
              <Box
                background={topic.selected ? '#FFD510' : '#fff'}
                borderRadius="20px"
                color="#000"
                cursor="pointer"
                px="20px"
                py="12px"
                onClick={() => {
                  clickTopic(index);
                }}
              >
                <HStack>
                  <Icon as={MdSettings} />
                  <Text>{topic.title}</Text>
                </HStack>
              </Box>
            </WrapItem>
          );
        })}
      </Wrap>
      <Box
        pointerEvents={loading ? 'none' : 'initial'}
        mt="60px !important"
        background="#FF6BE8"
        boxShadow="0px 8px 0px #BB3FA8"
        borderRadius="8px"
        px="40px"
        py="16px"
        cursor="pointer"
        _hover={{
          transform: 'scale(0.98)',
        }}
        _active={{
          transform: 'scale(0.95)',
        }}
        onClick={() => {
          submitPayload(topics);
        }}
      >
        <Text fontFamily="press_start_2pregular" color="#fff">
          {loading ? 'Submitting...' : 'Register'}
        </Text>
      </Box>
    </VStack>
  );
};

export default RegisterScreen;
