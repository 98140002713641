import React, { useContext, useEffect } from 'react';
import {
  ChakraProvider,
  Box,
  Text,
  Link,
  VStack,
  Code,
  Grid,
  theme,
  Button,
  Stack,
  Spacer,
  SimpleGrid,
  HStack,
  Flex,
  Center,
  Image,
} from '@chakra-ui/react';

// import HorizontalScroll from 'react-horizontal-scrolling';

import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route, useNavigate } from 'react-router-dom';
// import your route components too

import { toJS } from 'mobx';
import { observer } from 'mobx-react-lite';

import MainStore from '../stores/MainStore';

import HowToPlayScreen from './HowToPlayScreen';
import Footer from './Footer';

import Logo from '../assets/logo.png';
import RegisterButtonBG from '../assets/register_button_bg.png';
import DownArrow from '../assets/down_button.png';
import RightArrow from '../assets/right_arrow.png';

const LandingScreen = observer(() => {
  let navigate = useNavigate();
  let store = useContext(MainStore);

  useEffect(() => {
    window.scrollTo(0, 0);

    if (store.userData) {
      if (store.userData.interests.length <= 0) {
        //no topics yet
        // navigate('topics'); /* skip for now till add/removing topics is working again */
        navigate('/topics');
      } else {
        navigate('/home');
      }
      // navigate('home');
    }
  }, [store.userData]);

  return (
    <Stack>
      <Flex w="100%" px="8px">
        <Center
          m="20px"
          backgroundColor="#FFF4B7"
          minW="160px"
          h="600px"
          display={{ base: 'none', md: 'flex' }}
        >
          <Box>
            <Text textAlign="center">160 x 600</Text>
            <Text textAlign="center">AD PLACEMENT</Text>
          </Box>
        </Center>
        <VStack flex="1" h="100vh" minH="500px" spacing={10} py="40px">
          <Image src={Logo}></Image>
          <Box>
            <Text
              fontFamily="press_start_2pregular"
              fontSize="20px"
              textAlign="center"
              color="#FFD510"
              textShadow="-3px 3px 0px rgba(0, 0, 0, 0.35)"
            >{`Think you can pick`}</Text>
            <Text
              fontSize="20px"
              fontFamily="press_start_2pregular"
              textAlign="center"
              color="#FFD510"
              textShadow="-3px 3px 0px rgba(0, 0, 0, 0.35)"
            >{`The lucky pixel?`}</Text>
          </Box>
          <Box
            w={{ base: '90%', md: '432px' }}
            p="6px"
            textAlign="center"
            fontFamily="press_start_2pregular"
            color="#fff"
            backgroundImage={RegisterButtonBG}
            backgroundRepeat="no-repeat"
            backgroundPosition="center"
            backgroundSize="contain"
            cursor="pointer"
            _hover={{
              transform: 'scale(0.98)',
            }}
            onClick={() => navigate('/register')}
          >
            <Flex justifyContent="center">
              <Image src={RightArrow} mr="10px" />
              <Text>{`Play Now`}</Text>
            </Flex>
          </Box>
          {/* {!store.loginData && (
            <Button
              variant="link"
              fontFamily="press_start_2pregular"
              color="#fff"
              onClick={() => {
                navigate('/login');
              }}
            >
              Sign in
            </Button>
          )} */}
          <Button
            variant="link"
            fontFamily="press_start_2pregular"
            color="#fff"
            onClick={() => {
              const anchor = document.querySelector('#how-to-play');
              anchor.scrollIntoView({ behavior: 'smooth' });
            }}
          >
            How to Play
          </Button>
          {/* <Button
            variant="link"
            fontFamily="press_start_2pregular"
            color="#fff"
            onClick={() => {
              const anchor = document.querySelector('#prizes');
              anchor.scrollIntoView({ behavior: 'smooth' });
            }}
          >
            Prizes
          </Button> */}
          <Button
            variant="link"
            fontFamily="press_start_2pregular"
            color="#fff"
            onClick={() => {
              // const anchor = document.querySelector('#marketplace');
              // anchor.scrollIntoView({ behavior: 'smooth' });
            }}
          >
            Marketplace
          </Button>
          {/* <Button
            variant="link"
            fontFamily="press_start_2pregular"
            color="#fff"
            onClick={() => navigate('/faqs')}
          >
            FAQs
          </Button> */}
          {store.loginData && (
            <Button
              variant="link"
              fontFamily="press_start_2pregular"
              color="#fff"
              onClick={() => store._logout()}
            >
              Logout
            </Button>
          )}

          <Spacer />
          {/* <Box
            cursor="pointer"
            _hover={{
              transform: 'scale(0.98)',
            }}
          >
            <Image src={DownArrow} />
          </Box> */}
        </VStack>
        <Center
          m="20px"
          backgroundColor="#FFF4B7"
          minW="160px"
          h="600px"
          display={{ base: 'none', md: 'flex' }}
        >
          <Box>
            <Text textAlign="center">160 x 600</Text>
            <Text textAlign="center">AD PLACEMENT</Text>
          </Box>
        </Center>
      </Flex>
      <HowToPlayScreen id="how-to-play" />
      <VStack py="60px">
        <Center
          backgroundColor="#FFF4B7"
          w="728px"
          h="90px"
          display={{ base: 'none', md: 'flex' }}
        >
          <Text textAlign="center">728 x 90 AD PLACEMENT</Text>
        </Center>
      </VStack>
      {/* <VStack>
        <Center
          backgroundColor="#707070"
          w={{ base: '100%', md: '1170px' }}
          h="400px"
          boxShadow="-8px 8px 0px rgba(0, 0, 0, 0.25)"
          borderRadius="30px"
        >
          <Text textAlign="center">Carousel</Text>
        </Center>
      </VStack> */}
      {/* <Box p={{ base: '8px', md: '40px' }}>
        <HStack py="20px" id="prizes">
          <Text fontFamily="press_start_2pregular" color="#fff">
            Prizes
          </Text>
          <Spacer />
          <HStack>
            <Button variant="link" color="#fff">
              View all prizes
            </Button>
            <Image src={RightArrow} />
          </HStack>
        </HStack>
        <Box whiteSpace="nowrap" overflowX="auto" overflowY="hidden">
          {[0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0].map((i, index) => {
            return (
              <Box
                key={`prize${index}`}
                display="inline-block"
                w="270px"
                h="315px"
                border="3px solid #000000"
                borderRadius="10px"
                background="#2128AB"
                boxShadow="-4px 4px 0px rgba(0, 0, 0, 0.25)"
                mr="20px"
                mb="20px"
              >
                <VStack pt="20px">
                  <Box
                    w="240px"
                    h="200px"
                    background="#9CABC0"
                    borderRadius="15px"
                    // m="0 auto"
                  ></Box>
                  <Box w="240px">
                    <Text color="#fff">Name of prize here</Text>
                  </Box>
                  <Box w="240px" m="0 auto"></Box>
                </VStack>
              </Box>
            );
          })}
        </Box>
        <HStack py="20px" id="marketplace">
          <Text fontFamily="press_start_2pregular" color="#fff">
            Marketplace
          </Text>
          <Spacer />
          <HStack>
            <Button variant="link" color="#fff">
              View all products
            </Button>
            <Image src={RightArrow} />
          </HStack>
        </HStack>
        <Box whiteSpace="nowrap" overflowX="auto" overflowY="hidden">
          {[0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0].map((i, index) => {
            return (
              <Box
                key={`market${index}`}
                display="inline-block"
                w="270px"
                h="315px"
                border="3px solid #000000"
                borderRadius="10px"
                background="#2128AB"
                boxShadow="-4px 4px 0px rgba(0, 0, 0, 0.25)"
                mr="20px"
                mb="20px"
              >
                <VStack pt="20px">
                  <Box
                    w="240px"
                    h="200px"
                    background="#9CABC0"
                    borderRadius="15px"
                    // m="0 auto"
                  ></Box>
                  <Box w="240px">
                    <Text color="#fff">Name of product here</Text>
                    <Text color="#fff">lorem ipsum</Text>
                  </Box>
                  <Box w="240px" m="0 auto"></Box>
                </VStack>
              </Box>
            );
          })}
        </Box>
      </Box> */}
      {/* <VStack py="120px">
        <Center
          backgroundColor="#FFF4B7"
          w="728px"
          h="90px"
          display={{ base: 'none', md: 'flex' }}
        >
          <Text textAlign="center">728 x 90 AD PLACEMENT</Text>
        </Center>
      </VStack> */}
      <Footer />
    </Stack>
  );
});

export default LandingScreen;
