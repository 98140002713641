import React, { useContext, useEffect, useState } from 'react';
import {
  ChakraProvider,
  Box,
  Text,
  Link,
  VStack,
  Code,
  Grid,
  theme,
  Button,
  Stack,
  Spacer,
  SimpleGrid,
  HStack,
  Flex,
  Center,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  FormControl,
  FormLabel,
  FormErrorMessage,
  FormHelperText,
  Input,
  Tag,
  TagLabel,
  TagLeftIcon,
  TagRightIcon,
  TagCloseButton,
  Wrap,
  WrapItem,
  Icon,
  Image,
  InputGroup,
  InputRightElement,
} from '@chakra-ui/react';

import { MdSettings } from 'react-icons/md';
import { FaFacebook, FaTwitter, FaGoogle } from 'react-icons/fa';

// import HorizontalScroll from 'react-horizontal-scrolling';

import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route, useNavigate } from 'react-router-dom';
// import your route components too

import { observer } from 'mobx-react-lite';

import MainStore from '../stores/MainStore';

import Footer from './Footer';
import Header from './Header';

import Logo from '../assets/logo.png';

const ForgotPasswordScreen = () => {
  let navigate = useNavigate();
  let store = useContext(MainStore);

  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState('');

  const resetPassword = () => {
    setLoading(true);
    store
      ._forgotPassword(email)
      .then(res => {
        setEmail('');
        setLoading(false);
        navigate('/');
      })
      .catch(err => {
        setEmail('');
        setLoading(false);
      });
  };

  return (
    <Stack>
      <Header hideMenu={true} />
      <Flex w="100%" pb="80px">
        <Center
          m="20px"
          backgroundColor="#FFF4B7"
          minW="160px"
          h="600px"
          display={{ base: 'none', md: 'flex' }}
        >
          <Box>
            <Text textAlign="center">160 x 600</Text>
            <Text textAlign="center">AD PLACEMENT</Text>
          </Box>
        </Center>
        <VStack flex="1" minH="500px">
          <Box w="100%" maxW="728px" color="#fff">
            <VStack color="#fff" pt="40px" textAlign="left" spacing="20px">
              <VStack>
                <Text
                  fontFamily="press_start_2pregular"
                  color="#fff"
                  fontSize="20px"
                  textShadow="-3px 3px 0px rgba(0, 0, 0, 0.35);"
                >
                  Forgot Password
                </Text>
              </VStack>
              <FormControl>
                <FormLabel fontFamily="nunitobold">Email address</FormLabel>
                <Input
                  background="#fff"
                  color="#000"
                  type="email"
                  value={email}
                  onChange={e => setEmail(e.target.value)}
                />
              </FormControl>

              <Box
                pointerEvents={loading ? 'none' : 'initial'}
                mt="60px !important"
                background="#FF6BE8"
                boxShadow="0px 8px 0px #BB3FA8"
                borderRadius="8px"
                px="40px"
                py="16px"
                cursor="pointer"
                _hover={{
                  transform: 'scale(0.98)',
                }}
                _active={{
                  transform: 'scale(0.95)',
                }}
                onClick={() => resetPassword()}
              >
                <Text fontFamily="press_start_2pregular" color="#fff">
                  {loading ? 'Submitting...' : 'Reset Password'}
                </Text>
              </Box>
            </VStack>
          </Box>
        </VStack>
        <Center
          m="20px"
          backgroundColor="#FFF4B7"
          minW="160px"
          h="600px"
          display={{ base: 'none', md: 'flex' }}
        >
          <Box>
            <Text textAlign="center">160 x 600</Text>
            <Text textAlign="center">AD PLACEMENT</Text>
          </Box>
        </Center>
      </Flex>
      <Footer />
    </Stack>
  );
};

export default ForgotPasswordScreen;
