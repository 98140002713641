import React, { useContext, useEffect, useState, useRef } from 'react';
import {
  ChakraProvider,
  Box,
  Text,
  Link,
  VStack,
  Code,
  Grid,
  theme,
  Button,
  Stack,
  Spacer,
  SimpleGrid,
  HStack,
  Flex,
  Center,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  FormControl,
  FormLabel,
  FormErrorMessage,
  FormHelperText,
  Input,
  Tag,
  TagLabel,
  TagLeftIcon,
  TagRightIcon,
  TagCloseButton,
  Wrap,
  WrapItem,
  Icon,
  Image,
  Checkbox,
  CheckboxGroup,
} from '@chakra-ui/react';

import { MdSettings } from 'react-icons/md';
import { FaFacebook, FaTwitter, FaGoogle } from 'react-icons/fa';

// import HorizontalScroll from 'react-horizontal-scrolling';

import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route, useNavigate } from 'react-router-dom';
// import your route components too

import { observer } from 'mobx-react-lite';

import MainStore from '../stores/MainStore';

import Header from './Header';
import Footer from './Footer';

import Logo from '../assets/logo.png';

// import 'flatpickr/dist/themes/material_green.css';
import 'flatpickr/dist/themes/airbnb.css';
import Flatpickr from 'react-flatpickr';
import moment from 'moment';

const TopicsScreen = () => {
  let navigate = useNavigate();
  const store = useContext(MainStore);

  useEffect(() => {
    // window.scrollTo(0, 0);
  });

  function submitPayload(topics) {
    //count before submitting
    var selectedTopics = topics.filter(t => t.selected);
    // console.log(selectedTopics);
    if (selectedTopics.length > 0) {
      //submit topics
      // navigate('/home');

      console.log(selectedTopics);

      // await store._addInterest({ interest_id: tempTopics[index].id });
    } else {
      store._globalToast({
        title: 'Error',
        description: 'You need to choose at least 1 topic.',
        status: 'error',
        isClosable: true,
      });
    }
  }

  return (
    <Stack>
      <Header hideMenu={true} />
      <Flex w="100%" pb="80px">
        <Center
          m="20px"
          backgroundColor="#FFF4B7"
          minW="160px"
          h="600px"
          display={{ base: 'none', md: 'flex' }}
        >
          <Box>
            <Text textAlign="center">160 x 600</Text>
            <Text textAlign="center">AD PLACEMENT</Text>
          </Box>
        </Center>
        <VStack flex="1" minH="500px">
          <Box w="100%" maxW="728px" color="#fff">
            {/* <SelectTopics submitPayload={submitPayload} /> */}
            <SelectTopicsTab />
          </Box>
        </VStack>
        <Center
          m="20px"
          backgroundColor="#FFF4B7"
          minW="160px"
          h="600px"
          display={{ base: 'none', md: 'flex' }}
        >
          <Box>
            <Text textAlign="center">160 x 600</Text>
            <Text textAlign="center">AD PLACEMENT</Text>
          </Box>
        </Center>
      </Flex>
      <Footer />
    </Stack>
  );
};

const SelectTopics = ({ loading, submitPayload }) => {
  let navigate = useNavigate();

  const store = useContext(MainStore);

  const [topics, setTopics] = useState([]);
  const [lock, setLock] = useState(false);

  useEffect(() => {
    store._getInterests().then(res => {
      // console.log('Interests', res);
      //process interests
      const interests = res.map(i => {
        return { id: i.id, title: i.name, selected: false };
      });
      setTopics(interests);
    });
  }, []);

  const clickTopic = async index => {
    let tempTopics = [...topics];

    tempTopics[index].selected = !topics[index].selected;

    if (topics.filter(t => t.selected).length > 3) {
      //revert
      tempTopics[index].selected = !topics[index].selected;
      store._globalToast({
        title: 'Error',
        description: 'You can only choose up to 3 topics.',
        status: 'error',
        isClosable: true,
      });

      return;
    }

    // check what it was set to
    if (tempTopics[index].selected) {
      await store._addInterest({ interest_id: tempTopics[index].id });
    } else {
      await store._deleteInterest({ interest_id: tempTopics[index].id });
    }

    setTopics(tempTopics);
  };

  return (
    <VStack pt="40px" spacing="20px" p={{ base: '10px', md: '0px' }}>
      <Text
        fontFamily="press_start_2pregular"
        color="#fff"
        fontSize="20px"
        // lineHeight="36px"
        textShadow="-3px 3px 0px rgba(0, 0, 0, 0.35);"
      >
        Choose 3 topics that you like
      </Text>
      <Text>{`Customize the game and prizes according to your interests`}</Text>
      <br />
      <Wrap spacing="20px" justify="center" align="center">
        {topics.map((topic, index) => {
          return (
            <WrapItem key={`topic${index}`}>
              <Box
                background={topic.selected ? '#FFD510' : '#fff'}
                borderRadius="20px"
                color="#000"
                cursor="pointer"
                px="20px"
                py="12px"
                onClick={() => {
                  clickTopic(index);
                }}
              >
                <HStack>
                  <Icon as={MdSettings} />
                  <Text>{topic.title}</Text>
                </HStack>
              </Box>
            </WrapItem>
          );
        })}
      </Wrap>
      <Box
        pointerEvents={loading ? 'none' : 'initial'}
        mt="60px !important"
        background="#FF6BE8"
        boxShadow="0px 8px 0px #BB3FA8"
        borderRadius="8px"
        px="40px"
        py="16px"
        cursor="pointer"
        _hover={{
          transform: 'scale(0.98)',
        }}
        _active={{
          transform: 'scale(0.95)',
        }}
        onClick={() => {
          submitPayload(topics);
        }}
      >
        <Text fontFamily="press_start_2pregular" color="#fff">
          {loading ? 'Submitting...' : 'Get started'}
        </Text>
      </Box>
    </VStack>
  );
};

const NewTopicsScreen = observer(() => {
  const store = useContext(MainStore);
  return (
    <>
      {store.userData && (
        <>
          <TopicsScreen />
          <SelectTopicsTab />
        </>
      )}
    </>
  );
});

const SelectTopicsTab = () => {
  let navigate = useNavigate();

  const store = useContext(MainStore);

  const topicsLoaded = useRef(false);
  const [topics, setTopics] = useState([]);
  const [lock, setLock] = useState(false);

  const [loading, setloading] = useState(false);

  useEffect(() => {
    store._getInterests().then(res => {
      //process interests
      const interests = res.map(i => {
        return { id: i.id, title: i.name, selected: false };
      });
      setTopics(interests);
    });
  }, []);

  useEffect(() => {
    if (!topicsLoaded.current && topics.length > 0) {
      topicsLoaded.current = true;
      updateTopics();
    }
  }, [topics]);

  useEffect(() => {
    if (topics.length > 0 && store.userData) {
      updateTopics();
    }
  }, [store.userData]);

  const updateTopics = () => {
    const interests = [...store.userData.interests];
    const tempTopics = [...topics];

    interests.map(i => {
      const index = tempTopics.findIndex(topic => topic.title === i);
      if (tempTopics.length > 0) tempTopics[index].selected = true;
    });

    setTopics(tempTopics);
  };

  const submitPayload = value => {
    setloading(true);
    store
      ._addInterests({ interests: value.filter(t => t.selected) })
      .then(res => {
        setloading(false);
        store._globalToast({
          title: 'Topics updated',
          description: '',
          status: 'success',
          isClosable: true,
        });
        navigate('/home');
      });
  };

  const clickTopic = async index => {
    let tempTopics = [...topics];

    tempTopics[index].selected = !topics[index].selected;

    if (topics.filter(t => t.selected).length > 3) {
      //revert
      tempTopics[index].selected = !topics[index].selected;
      store._globalToast({
        title: 'Error',
        description: 'You can only choose up to 3 topics.',
        status: 'error',
        isClosable: true,
      });

      return;
    }

    // check what it was set to
    // if (tempTopics[index].selected) {
    //   await store._addInterest({ interest_id: tempTopics[index].id });
    // } else {
    //   await store._deleteInterest({ interest_id: tempTopics[index].id });
    // }

    setTopics(tempTopics);
  };

  return (
    <VStack pt="40px" spacing="20px">
      <Text
        fontFamily="press_start_2pregular"
        color="#fff"
        fontSize="20px"
        // lineHeight="36px"
        textShadow="-3px 3px 0px rgba(0, 0, 0, 0.35);"
      >
        Choose 3 topics that you like
      </Text>
      <Text>{`Customize the game and prizes according to your interests`}</Text>
      <br />
      <Wrap spacing="20px" justify="center" align="center">
        {topics.map((topic, index) => {
          return (
            <WrapItem key={`topic${index}`}>
              <Box
                background={topic.selected ? '#FFD510' : '#fff'}
                borderRadius="20px"
                color="#000"
                cursor="pointer"
                px="20px"
                py="12px"
                onClick={() => {
                  clickTopic(index);
                }}
              >
                <HStack>
                  <Icon as={MdSettings} />
                  <Text>{topic.title}</Text>
                </HStack>
              </Box>
            </WrapItem>
          );
        })}
      </Wrap>
      <Box
        pointerEvents={loading ? 'none' : 'initial'}
        mt="60px !important"
        background="#FF6BE8"
        boxShadow="0px 8px 0px #BB3FA8"
        borderRadius="8px"
        px="40px"
        py="16px"
        cursor="pointer"
        _hover={{
          transform: 'scale(0.98)',
        }}
        _active={{
          transform: 'scale(0.95)',
        }}
        onClick={() => {
          submitPayload(topics);
        }}
      >
        <Text fontFamily="press_start_2pregular" color="#fff">
          {loading ? 'Submitting...' : 'Get Started'}
        </Text>
      </Box>
    </VStack>
  );
};

export default NewTopicsScreen;
