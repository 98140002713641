import React, { useContext, useEffect, useState, useRef } from 'react';
import {
  ChakraProvider,
  Box,
  Text,
  Link,
  VStack,
  Code,
  Grid,
  theme,
  Button,
  Stack,
  Spacer,
  SimpleGrid,
  HStack,
  Flex,
  Center,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  FormControl,
  FormLabel,
  FormErrorMessage,
  FormHelperText,
  Input,
  Tag,
  TagLabel,
  TagLeftIcon,
  TagRightIcon,
  TagCloseButton,
  Wrap,
  WrapItem,
  Icon,
  IconButton,
  Image,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Spinner,
  Skeleton,
  SkeletonCircle,
  SkeletonText,
  Container,
} from '@chakra-ui/react';

import { BsFillHeartFill, BsQuestionSquare } from 'react-icons/bs';
import { FaCoins } from 'react-icons/fa';

// import HorizontalScroll from 'react-horizontal-scrolling';

import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route, useNavigate } from 'react-router-dom';
// import your route components too

import { toJS } from 'mobx';
import { observer } from 'mobx-react-lite';

import MainStore from '../stores/MainStore';

import Footer from './Footer';

import Prize from '../assets/box_closed.png';
import PrizeOpen from '../assets/box_open.png';

import Logo from '../assets/logo.png';
import Heart from '../assets/heart.png';
import HeartEmpty from '../assets/heart_empty.png';
import Tokens from '../assets/token.png';
import CloseModal from '../assets/close_button.png';
import MajorPrize from '../assets/smiley_major.png';
import MinorPrize from '../assets/smiley_minor.png';
import TokenPrize from '../assets/smiley_token.png';
import Lose from '../assets/smiley_lose.png';

import moment from 'moment';
import Header from './Header';
import ConfettiExplosion from 'react-confetti-explosion';

const PlayScreen = observer(() => {
  let navigate = useNavigate();
  const store = useContext(MainStore);
  const [loading, setLoading] = useState(false);
  const [showAd, setShowAd] = useState(false);
  const [adData, setAdData] = useState({});
  const [boxes, setBoxes] = useState([]);
  var timer = useRef();
  const [countDown, setCountDown] = useState(0);
  const [showConfirm, setShowConfirm] = useState(false);
  const [selected, setSelected] = useState();
  const [showResult, setShowResult] = useState(false);
  const [result, setResult] = useState();

  const [skipTime] = useState(5000);

  useEffect(() => {
    store._getGame();

    // // return;
    // if (!store.userData) {
    //   navigate('/');
    // } else {
    //   store._getGame();
    // }

    // let array = [];
    // for (let index = 0; index < 300; index++) {
    //   array.push(0);
    // }
    // setBoxes(array);
  }, []);

  const openBox = e => {
    setSelected(e);
    setShowConfirm(true);
  };

  const handleClick = e => {
    setLoading(true);
    store
      ._openSlot(e)
      .then(res => {
        setLoading(false);
        // console.log('RESULT Data', res);
        setAdData(res.ad);
        setShowAd(true);
        setSelected(null);
        setShowConfirm(false);

        setResult(res);
        // setShowResult(true);
      })
      .catch(err => {
        console.log(err);
        store._globalToast({
          title: err.response.data.message,
          // description: err.response.data.message,
          status: 'error',
          duration: 9000,
          isClosable: true,
        });

        setLoading(false);
        setSelected(null);
        setShowConfirm(false);
      });
  };

  const setHearts = () => {
    const display = [];
    for (let i = 0; i < 3; i++) {
      if (i < store.userData.hearts) {
        display.push(<Image key={`h${i}`} src={Heart} />);
      } else {
        display.push(<Image key={`h${i}`} src={HeartEmpty} />);
      }
    }
    return display;
  };

  const getTime = () => {
    const seconds = countDown;

    const result2 = new Date(seconds * 1000).toISOString().slice(11, 19);

    return result2;
  };

  useEffect(() => {
    timer = setInterval(() => {
      if (store.gameData) {
        //compute timer
        const a = moment(store.gameData.end);
        const b = moment().utc(false);

        var time = a.diff(b, 'seconds');

        setCountDown(time);
      }
    }, 1000);

    return () => {
      clearInterval(timer.current);
    };
  }, []);

  return (
    <Stack>
      <Header hidePlayNow />
      {/* <HStack pt="15px" px="40px">
        <Image h="85px" src={Logo} />
        <Spacer />
        {store.userData && (
          <>
            <HStack>{setHearts()}</HStack>
            <Text
              ml="20px !important"
              fontFamily="press_start_2pregular"
              color="#fff"
            >
              {getTime()}
            </Text>
            <HStack ml="40px !important">
              <Image src={Tokens} />
              <Text fontFamily="press_start_2pregular" color="#fff">
                {`${store.userData.tokens} tokens`}
              </Text>
            </HStack>
          </>
        )}
        <Box
          ml="20px !important"
          background="#6D1BE8"
          boxShadow="0px 6px 0px #2A0A5A"
          borderRadius="8px"
          px="40px"
          py="16px"
          cursor="pointer"
          _hover={{
            transform: 'scale(0.98)',
          }}
          _active={{
            transform: 'scale(0.95)',
          }}
          onClick={() => navigate('/')}
        >
          <Text fontFamily="press_start_2pregular" color="#fff">
            Back to menu
          </Text>
        </Box>
      </HStack> */}
      <VStack my="40px !important">
        <Center
          backgroundColor="#FFF4B7"
          w={{ base: '100%', md: '728px' }}
          h="90px"
        >
          <Text textAlign="center">728 x 90 AD PLACEMENT</Text>
        </Center>
      </VStack>
      <Wrap justify="center" p="40px">
        {store.gameData &&
          store.gameData.slots.map((i, index) => {
            return (
              <WrapItem key={`p${index}`} pos={'relative'}>
                <Box cursor="pointer" onClick={() => openBox(i)}>
                  {/* <Icon as={BsQuestionSquare} boxSize="24px" /> */}
                  <Image name={`${index}`} src={i.opened ? PrizeOpen : Prize} />
                  <Text
                    pos={'absolute'}
                    right="6px"
                    bottom={'0px'}
                    fontWeight={'bold'}
                    textShadow={'1px 1px white'}
                    pointerEvents={'none'}
                  >{`${i.slot}`}</Text>
                </Box>
              </WrapItem>
            );
          })}
      </Wrap>
      <VStack mt="40px !important" mb="80px !important">
        <Center
          backgroundColor="#FFF4B7"
          w={{ base: '100%', md: '728px' }}
          h="90px"
        >
          <Text textAlign="center">728 x 90 AD PLACEMENT</Text>
        </Center>
      </VStack>
      <Footer />
      {/* <LoadingModal loading={loading} /> */}
      <AdModal
        data={adData}
        onClose={() => {
          setShowAd(false);
          setShowResult(true);
        }}
        isOpen={showAd}
      />
      <OpenBox
        data={selected}
        handleClick={handleClick}
        onClose={() => setShowConfirm(false)}
        isOpen={showConfirm}
      />
      <ResultBox
        data={result}
        handleClick={handleClick}
        onClose={win => {
          setShowResult(false);
          if (win) navigate('/claim');
        }}
        isOpen={showResult}
      />
    </Stack>
  );
});

const LoadingModal = ({ loading }) => {
  // const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <Modal isOpen={loading} isCentered>
      <ModalOverlay />
      <ModalContent bg="none">
        {/* <ModalHeader>Modal Title</ModalHeader> */}
        {/* <ModalCloseButton /> */}
        {/* <ModalBody> */}
        <Box m="0 auto">
          <Spinner
            thickness="4px"
            speed="0.65s"
            emptyColor="gray.200"
            color="blue.500"
            size="xl"
          />
        </Box>
        {/* </ModalBody> */}
        {/* <ModalFooter> */}
        {/* <Button onClick={onClose}>Close</Button> */}
        {/* </ModalFooter> */}
      </ModalContent>
    </Modal>
  );
};

const AdModal = ({ data, onClose, isOpen }) => {
  const [time, setTime] = useState(5);
  const [allowSkip, setAllowSkip] = useState(false);

  const [isVideo, setIsVideo] = useState(false);
  const [videoLoaded, setVideoLoaded] = useState(false);

  let intervalRef = useRef();

  const decreaseNum = () => setTime(prev => prev - 1);

  useEffect(() => {
    if (data && data.media_files) {
      data.media_files.map(media => {
        if (media.type === 'video') {
          setIsVideo(true);
        }
      });
    }
  }, []);

  useEffect(() => {
    if (time <= 0) {
      clearInterval(intervalRef.current);
      setAllowSkip(true);
      setTime(5);
    }
  }, [time]);

  useEffect(() => {
    if (isOpen && !isVideo) {
      intervalRef.current = setInterval(decreaseNum, 1000);
    } else if (isOpen && isVideo && videoLoaded) {
      if (!intervalRef.current) {
        intervalRef.current = setInterval(decreaseNum, 1000);
      }
    }

    return () => clearInterval(intervalRef.current);
  }, [isOpen, videoLoaded, isVideo]);

  const closeModal = () => {
    if (intervalRef.current) clearInterval(intervalRef.current);
    setTime(5);
    setAllowSkip(false);
    onClose();
  };

  return (
    <Modal
      // onClose={onClose}
      isOpen={isOpen}
      size="xl"
      closeOnOverlayClick={false}
    >
      <ModalOverlay />
      <ModalContent background={'transparent'}>
        {/* <ModalHeader>{data ? data.name : 'Ad here'}</ModalHeader> */}
        {/* <ModalCloseButton /> */}
        <ModalBody>
          {/* <Text>
            {data ? `Description: ${data.description}` : 'Description here'}
          </Text> */}
          <Skeleton h={videoLoaded ? 'auto' : '480px'} isLoaded={videoLoaded}>
            <Stack onClick={() => window.open(data.url)} cursor="pointer">
              {data &&
                data.media_files &&
                data.media_files.map((media, index) => {
                  if (media.type === 'video') {
                    return (
                      <video
                        key={`m_${index}`}
                        id="samp"
                        width="640"
                        height="480"
                        // controls
                        onLoadedData={() => {
                          console.log('loaded');
                          setVideoLoaded(true);
                        }}
                        onPlay={() => {
                          console.log('playing!');
                        }}
                        onEnded={closeModal}
                        autoPlay
                        muted
                      >
                        <source src={media.url} type="video/mp4" />
                        {/* <source
                        src={
                          'https://samplelib.com/lib/preview/mp4/sample-5s.mp4'
                        }
                        type="video/mp4"
                      /> */}
                      </video>
                    );
                  } else if (media.type === 'image') {
                    return <Image key={`m_${index}`} src={media.url} />;
                  }

                  return (
                    <Text
                      key={`m_${index}`}
                    >{`Media Unavailable  (${media.type})} - ${media.url}`}</Text>
                  );
                })}
            </Stack>
          </Skeleton>
          <Box pos={'absolute'} bottom="-40px" right="24px">
            {allowSkip ? (
              <Button onClick={closeModal}>Skip</Button>
            ) : (
              <Button pointerEvents="none">{`Skip in ${time}`}</Button>
            )}
          </Box>
        </ModalBody>
        {/* <ModalFooter>
          {allowSkip ? (
            <Button onClick={closeModal}>Skip</Button>
          ) : (
            <Button pointerEvents="none">{`Skip in ${time}`}</Button>
          )}
        </ModalFooter> */}
      </ModalContent>
    </Modal>
  );
};

const OpenBox = ({ data, handleClick, onClose, isOpen }) => {
  return (
    <Modal
      onClose={onClose}
      isOpen={isOpen}
      isCentered
      closeOnOverlayClick={false}
    >
      <ModalOverlay />
      <ModalContent bg="none" minW="100% !important">
        <Box m="0 auto" p="10px">
          <Box
            w="100%"
            maxW="563px"
            // h="397"
            backgroundColor="#F8CA4F"
            border="5px solid #77461E"
            boxShadow="-8px 8px 0px #77461E"
            borderRadius="40px"
          >
            <HStack pt="20px" px="30px">
              <Spacer />
              <Box
                cursor="pointer"
                _hover={{
                  transform: 'scale(0.98)',
                }}
                _active={{
                  transform: 'scale(0.95)',
                }}
                onClick={onClose}
              >
                <Image src={CloseModal} />
              </Box>
            </HStack>
            <VStack spacing="26px" p="30px">
              <Text
                fontWeight="400"
                fontFamily="press_start_2pregular"
                fontSize="26px"
                color="#77461E"
              >
                Are you sure?
              </Text>
              <Box
                w="100%"
                textAlign="center"
                background="#6D1BE8"
                boxShadow="0px 8px 0px #4C12A4"
                borderRadius="8px"
                px="30px"
                py="20px"
                cursor="pointer"
                _hover={{
                  transform: 'scale(0.95)',
                }}
                _active={{
                  transform: 'scale(0.92)',
                }}
                onClick={() => {
                  // console.log('Selected?', toJS(data));
                  handleClick(data);
                }}
              >
                <Text
                  fontFamily="press_start_2pregular"
                  color="#fff"
                  fontSize="20px"
                  // whiteSpace={'nowrap'}
                >
                  Yes, reveal now
                </Text>
              </Box>
              <Box
                w="100%"
                textAlign="center"
                background="#7A8698"
                boxShadow="0px 8px 0px #545A63"
                borderRadius="8px"
                px="30px"
                py="20px"
                cursor="pointer"
                _hover={{
                  transform: 'scale(0.98)',
                }}
                _active={{
                  transform: 'scale(0.95)',
                }}
                onClick={() => {
                  onClose();
                }}
              >
                <Text
                  fontFamily="press_start_2pregular"
                  color="#fff"
                  fontSize="20px"
                  // whiteSpace={'nowrap'}
                >
                  No, I want to change
                </Text>
              </Box>
            </VStack>
          </Box>
        </Box>
      </ModalContent>
    </Modal>
  );
};

const ResultBox = ({ data, handleClick, onClose, isOpen }) => {
  const [index, setIndex] = useState(3);

  const types = [
    {
      icon: MajorPrize,
      currency: 'CASH',
      title: "You're so lucky!",
      subtitle: "You've just won the major prize,",
      button_label: 'Claim prize',
    },
    {
      icon: MinorPrize,
      currency: 'CASH',
      title: 'Congratulations!',
      subtitle: "You've just won",
      button_label: 'Claim prize',
    },
    {
      icon: TokenPrize,
      currency: 'TOKENS',
      title: "You're so lucky!",
      subtitle: "You've just won",
      button_label: 'Pick again',
    },
    {
      icon: Lose,
      currency: 'NONE',
      title: 'That was close',
      subtitle: "You'll get it right next time",
      button_label: 'Pick again',
    },
  ];

  useEffect(() => {
    //check data
    if (data && data.type) {
      if (data.type === 'token') {
        setIndex(2);
      } else {
        setIndex(1);
      }
    } else {
      setIndex(3);
    }
  }, [isOpen]);

  return (
    <Modal isOpen={isOpen} isCentered closeOnOverlayClick={false}>
      <ModalOverlay />
      <ModalContent bg="none" minW="100% !important">
        <Box m="0 auto" p="10px">
          <Box
            w="100%"
            maxW="563px"
            // h="320px"
            backgroundColor="#F8CA4F"
            border="5px solid #000000"
            boxShadow="-8px 8px 0px #000000"
            borderRadius="40px"
          >
            <Box
              pos="absolute"
              top="-90px"
              left="50%"
              transform="translateX(-50%)"
              w="148px"
              h="148px"
              backgroundColor="white"
              border="5px solid #6D1BE8"
              boxShadow="-5px 5px 0px #500BB8"
            >
              <VStack pt="16px">
                <Image src={types[index].icon} />
                {isOpen && (
                  <Center pos={'absolute'} zIndex={99}>
                    {types[index].currency === 'TOKENS' && (
                      <ConfettiExplosion zIndex={99999} />
                    )}
                  </Center>
                )}

                <HStack mt="14px !important">
                  {types[index].currency === 'TOKENS' && <Image src={Tokens} />}
                  <Text
                    fontFamily="nunitobold"
                    fontSize="26px"
                    lineHeight="24px"
                  >
                    {types[index].currency === 'NONE' ? 'TRY' : `${data.prize}`}
                  </Text>
                </HStack>
                <Text
                  m="0px !important"
                  fontFamily="nunitobold"
                  fontSize="26px"
                >
                  {types[index].currency === 'NONE'
                    ? 'AGAIN'
                    : types[index].currency}
                </Text>
              </VStack>
            </Box>
            <HStack pt="20px" px="30px">
              <Spacer />
              <Box
                cursor="pointer"
                _hover={{
                  transform: 'scale(0.98)',
                }}
                _active={{
                  transform: 'scale(0.95)',
                }}
                onClick={() => {
                  onClose(
                    !(
                      types[index].currency === 'NONE' ||
                      types[index].currency === 'TOKENS'
                    )
                  );
                }}
                style={{ filter: 'grayscale(100%)' }}
              >
                <Image src={CloseModal} />
              </Box>
            </HStack>
            <VStack p="30px">
              <Text
                fontWeight="400"
                fontFamily="press_start_2pregular"
                fontSize="26px"
                color="#000000"
              >
                {types[index].title}
              </Text>
              <Container spacing="5px">
                <Text>{`${types[index].subtitle}`}</Text>
                <Text fontWeight="bold">{`${
                  data && data.prize ? data.prize : ''
                } ${types[index].currency === 'TOKENS' ? 'Tokens' : ''}`}</Text>
              </Container>

              <Box
                mt="30px !important"
                w="100%"
                textAlign="center"
                background="#6D1BE8"
                boxShadow="0px 8px 0px #4C12A4"
                borderRadius="8px"
                px="30px"
                py="20px"
                cursor="pointer"
                _hover={{
                  transform: 'scale(0.95)',
                }}
                _active={{
                  transform: 'scale(0.92)',
                }}
                onClick={() => {
                  // console.log('Selected?', toJS(data));
                  // handleClick(data);
                  onClose(
                    !(
                      types[index].currency === 'NONE' ||
                      types[index].currency === 'TOKENS'
                    ),
                    data
                  );
                }}
              >
                <Text
                  fontFamily="press_start_2pregular"
                  color="#fff"
                  fontSize="20px"
                >
                  {`${types[index].button_label}`}
                </Text>
              </Box>
            </VStack>
          </Box>
        </Box>
      </ModalContent>
    </Modal>
  );
};

export default PlayScreen;
