import React, { useContext, useEffect } from 'react';
import {
  ChakraProvider,
  Box,
  Text,
  Link,
  VStack,
  Code,
  Grid,
  theme,
  Button,
  Stack,
  Spacer,
  SimpleGrid,
  HStack,
  Flex,
  Center,
  Image,
} from '@chakra-ui/react';

// import HorizontalScroll from 'react-horizontal-scrolling';

import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route, useNavigate } from 'react-router-dom';
// import your route components too

import { observer } from 'mobx-react-lite';

import MainStore from '../stores/MainStore';

import PixelsIcon from '../assets/pixels_icon.png';
import HealthIcon from '../assets/health_icon.png';
import PrizesIcon from '../assets/prizes_icon.png';
import ClockIcon from '../assets/clock_icon.png';

const HowToPlayScreen = observer(props => {
  let navigate = useNavigate();
  const store = useContext(MainStore);

  const regOrPlay = () => {
    store.loginData ? navigate('/play') : navigate('/register');
  };

  return (
    <VStack {...props} py="20px">
      <Text
        fontFamily="press_start_2pregular"
        fontSize="20px"
        textAlign="center"
        color="#fff"
        textShadow="-3px 3px 0px rgba(0, 0, 0, 0.35)"
      >
        How to Play
      </Text>
      <SimpleGrid columns={{ base: 1, md: 4 }} mt="40px !important">
        <VStack px="40px">
          <Image src={PixelsIcon} />
          <Text
            mt="20px !important"
            fontFamily="press_start_2pregular"
            textAlign="center"
            color="#F8CA4F"
          >
            Pixels
          </Text>
          <Box color="white" textAlign="center">
            <Text>
              Your goal is to pick the lucky pixel containing a prize.
            </Text>
            <br />
            <Text>
              There are 200 pixels you can choose from but not all of them have
              prizes.
            </Text>
            <br />
            <Text>
              The placement of pixels and the prizes it contains are completely
              random per player. Sadly, sharing to a friend which pixels you
              chose won’t help.
            </Text>
          </Box>
        </VStack>
        <VStack px="40px">
          <Image src={HealthIcon} />
          <Text
            mt="20px !important"
            fontFamily="press_start_2pregular"
            textAlign="center"
            color="#F8CA4F"
          >
            Health
          </Text>
          <Box color="white" textAlign="center">
            <Text>You will have 3 tries every 4 hours.</Text>
            <br />
            <Text>
              Better use up all your tries since your health will not add up or
              carry over to the next 4 hours if you don’t use them all.
            </Text>
          </Box>
        </VStack>
        <VStack px="40px">
          <Image src={PrizesIcon} />
          <Text
            mt="20px !important"
            fontFamily="press_start_2pregular"
            textAlign="center"
            color="#F8CA4F"
          >
            Prizes
          </Text>
          <Box color="white" textAlign="center">
            <Text>
              There are 3 types of prizes in this game: Major prize, minor
              prize, and tokens.
            </Text>
            <br />
            <Text>
              Whoever is the lucky player to pick the major or minor prize
              first, gets to claim it. Once claimed, that prize will be
              unavailable to everyone else.
            </Text>
            <br />
            <Text>
              If you win tokens, you can get prizes at our prize marketplace,
              where you can
            </Text>
          </Box>
        </VStack>
        <VStack px="40px">
          <Image src={ClockIcon} />
          <Text
            mt="20px !important"
            fontFamily="press_start_2pregular"
            textAlign="center"
            color="#F8CA4F"
          >
            Clock
          </Text>
          <Box color="white" textAlign="center">
            <Text>
              At 12am, the game always resets. Placement of pixels will be
              randomized and prizes will be replenished. There will always be a
              chance to win everyday, so make sure to login and play when you
              have the time.
            </Text>
          </Box>
        </VStack>
      </SimpleGrid>
      <Box
        mt="60px !important"
        background="#FF6BE8"
        boxShadow="0px 8px 0px #BB3FA8"
        borderRadius="8px"
        px="40px"
        py="16px"
        cursor="pointer"
        _hover={{
          transform: 'scale(0.98)',
        }}
        _active={{
          transform: 'scale(0.95)',
        }}
        onClick={() => {
          // navigate('/register');
          regOrPlay();
        }}
      >
        <Text fontFamily="press_start_2pregular" color="#fff">
          Play Now
        </Text>
      </Box>
    </VStack>
  );
});

export default HowToPlayScreen;
